import React, { useMemo } from 'react'
import { Link } from '@chakra-ui/core'
import { extractFileNameFromS3Link, getFileExtension, getFileNameWithoutExtension, openInCloudViewer } from 'utils'
import { TruncatedFileName } from './TruncatedFileName'

interface IFileLinkProps {
  isFileOpened: boolean
  url: string
  maxWidth?: string
  children?: React.ReactNode
}

export const FileLink = ({ isFileOpened, url, maxWidth = '300px', children }: IFileLinkProps) => {
  const fileNameFromLink = useMemo(() => extractFileNameFromS3Link(url) || '', [url])
  const fileName = getFileNameWithoutExtension(fileNameFromLink) || ''
  const fileExtension = getFileExtension(fileNameFromLink)

  return isFileOpened ? (
    <Link href={openInCloudViewer(url)} target="_blank" rel="noopener noreferrer">
      {children}
      <TruncatedFileName fileName={fileName} fileExtension={fileExtension} maxWidth={maxWidth} />
    </Link>
  ) : (
    <>
      {children}
      <TruncatedFileName fileName={fileName} fileExtension={fileExtension} maxWidth={maxWidth} />
    </>
  )
}
