import { Box, Button, Flex, Text } from '@chakra-ui/core'
import { ImageSlider } from 'components/PollTask/ImageSlider'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import { useMediaState } from 'hooks/useMediaState'
import React, { useState } from 'react'
import { FaRegTrashAlt } from 'react-icons/fa'
import { IoMdImages } from 'react-icons/io'

interface IProps {
  attachedImages: string[]
  setAttachedImages: (value: string[]) => void
}
export const InspirationImage = ({ attachedImages, setAttachedImages }: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const t_add_image = useLocaleText('t_add_image')
  const { uploadedImagesURL, handleMediaUploading, addMediaHandler, imageInputRef, handleDeleteMedia } = useMediaState({
    images: attachedImages,
  })

  const handleUploadImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = await handleMediaUploading(e)
    if (!data || !data.uploadedImages.length) return
    const newImages = [...uploadedImagesURL, ...data.uploadedImages]
    setAttachedImages(newImages)
  }

  const handleDeleteImage = () => {
    setAttachedImages([])
    handleDeleteMedia(currentIndex)
  }

  return (
    <>
      {uploadedImagesURL.length === 0 ? (
        <Flex flexDir={'column'} alignItems="center" py={'10px'} borderRadius="10px" border="1px solid #e2e8f0">
          <Button
            borderRadius="full"
            p="5px"
            m="auto"
            h="auto"
            bg="transparent"
            onClick={addMediaHandler}
            cursor="pointer"
            transition={'0.2s ease-in-out'}
            _hover={{ bg: 'transparent', transform: 'scale(1.1)' }}
            _focus={{ outline: 'none' }}
          >
            <IoMdImages size={28} color="gray" />
          </Button>
          <Text color={colors.greyDark} pb="10px" fontWeight="400">
            {t_add_image}
          </Text>
          <input
            type="file"
            accept="image/*"
            multiple
            ref={imageInputRef}
            style={{ display: 'none' }}
            onChange={handleUploadImages}
          />
        </Flex>
      ) : (
        <Flex w="100%" flexDir="column">
          <Box w="80%" my="20px" borderRadius="20px" mx="auto" overflow="hidden" border="1px solid #f6f5f5">
            <ImageSlider images={uploadedImagesURL} height="400px" onSlideChange={setCurrentIndex} />
          </Box>
          <Flex w="80%" mx="auto" p="10px" justifyContent="center" style={{ columnGap: '25px' }}>
            <Button
              cursor="pointer"
              m="auto"
              h="auto"
              p="10px"
              borderRadius="full"
              transition={'0.2s ease-in-out'}
              _hover={{ transform: 'scale(1.1)' }}
              _focus={{ outline: 'none' }}
              onClick={addMediaHandler}
            >
              <IoMdImages size="23" cursor="pointer" />
            </Button>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={imageInputRef}
              style={{ display: 'none' }}
              onChange={handleUploadImages}
            />

            <Button
              cursor="pointer"
              m="auto"
              h="auto"
              p="10px"
              borderRadius="full"
              transition={'0.2s ease-in-out'}
              _hover={{ transform: 'scale(1.1)' }}
              _focus={{ outline: 'none' }}
              onClick={handleDeleteImage}
            >
              <FaRegTrashAlt size="20" cursor="pointer" />
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
}
