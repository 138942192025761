import React, { memo } from 'react'
import { StoreeEditor } from '../StoreeEditor'
import { Flex } from '@chakra-ui/core'
import { MessageInput } from '../MessageInput'
import { OptionType } from '../constants'
import { TitleInput } from '../TitleInput'
import keys from 'constants/keys'
import PollTaskCreation from '../PollTaskForm'
import { FormField } from '../PollTaskForm/Subtask/utils'

interface IProps {
  selectedOptionType: string
  editorValue: string
  setEditorValue: (text: string) => void
  setTitle: (text: string) => void
  title: string
  placeholder: string
  message: string
  setMessage: (text: string) => void
  postType?: number
  isEditing: boolean
  isSwitchMultipleAnswersActive: boolean
  setIsSwitchMultipleAnswersActive: (value: boolean) => void
  setFromTs: (value: string) => void
  setDeadline: (value: string) => void
  questionText: string
  setQuestionText: (value: string) => void
  pollOptions: string[]
  setPollOptions: React.Dispatch<React.SetStateAction<string[]>>
  setIsRecurring: (value: boolean) => void
  setRecurrenceInterval: (value: number | null) => void
  setRecurrenceFrequency: (value: number | null) => void
  recurrenceInterval: number | null
  recurrenceFrequency: number | null
  setPollSubTasks: (value: FormField[]) => void
  setIsValidPollSubtasks: (value: boolean) => void
  setPollDescription: (value: string) => void
  setTaskView: (value: number) => void
  taskView: number
  attachedImages: string[]
  setAttachedImages: (value: string[]) => void
}
export const InputBlock = memo(
  ({
    selectedOptionType,
    editorValue,
    setEditorValue,
    setTitle,
    title,
    placeholder,
    message,
    setMessage,
    postType,
    isEditing,
    isSwitchMultipleAnswersActive,
    setIsSwitchMultipleAnswersActive,
    setFromTs,
    setDeadline,
    questionText,
    setQuestionText,
    pollOptions,
    setPollOptions,
    setIsRecurring,
    setRecurrenceInterval,
    setRecurrenceFrequency,
    recurrenceInterval,
    recurrenceFrequency,
    setPollSubTasks,
    setIsValidPollSubtasks,
    setPollDescription,
    setTaskView,
    taskView,
    attachedImages,
    setAttachedImages,
  }: IProps) => {
    if (postType !== undefined) {
      switch (postType) {
        case keys.POST_TYPE.REGULAR:
        case keys.POST_TYPE.LINKED:
          return <StoreeEditor editorValue={editorValue} setEditorValue={setEditorValue} placeholder={placeholder} />
        case keys.POST_TYPE.POLL:
          return (
            <PollTaskCreation
              {...{
                isSwitchMultipleAnswersActive,
                setIsSwitchMultipleAnswersActive,
                setFromTs,
                setDeadline,
                questionText,
                setQuestionText,
                pollOptions,
                setPollOptions,
                setIsRecurring,
                setRecurrenceInterval,
                setRecurrenceFrequency,
                recurrenceInterval,
                recurrenceFrequency,
                setPollDescription,
                setTaskView,
                taskView,
                setPollSubTasks,
                setIsValidPollSubtasks,
                attachedImages,
                setAttachedImages,
              }}
            />
          )
        default:
          return (
            <Flex flexDir="column" minH="150px">
              <TitleInput setTitle={setTitle} title={title} selectedOptionType={selectedOptionType} />
              {!isEditing && (
                <MessageInput message={message} setMessage={setMessage} selectedOptionType={selectedOptionType} />
              )}
            </Flex>
          )
      }
    } else {
      switch (selectedOptionType) {
        case OptionType.POST:
          return <StoreeEditor editorValue={editorValue} setEditorValue={setEditorValue} placeholder={placeholder} />
        case OptionType.POLL:
          return (
            <PollTaskCreation
              {...{
                isSwitchMultipleAnswersActive,
                setIsSwitchMultipleAnswersActive,
                setFromTs,
                setDeadline,
                questionText,
                setQuestionText,
                pollOptions,
                setPollOptions,
                setIsRecurring,
                setRecurrenceInterval,
                setRecurrenceFrequency,
                recurrenceInterval,
                recurrenceFrequency,
                setPollDescription,
                setTaskView,
                taskView,
                setPollSubTasks,
                setIsValidPollSubtasks,
                attachedImages,
                setAttachedImages,
              }}
            />
          )
        default:
          return (
            <Flex flexDir="column" minH="150px">
              <TitleInput setTitle={setTitle} title={title} selectedOptionType={selectedOptionType} />
              {!isEditing && (
                <MessageInput message={message} setMessage={setMessage} selectedOptionType={selectedOptionType} />
              )}
            </Flex>
          )
      }
    }
  }
)
