import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/core'
import { Loader } from 'components/CommonComponents/Loader'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'

const ExternalSite = () => {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { uid } = useSelector((state: RootState) => state.auth)
  const { rid } = useSelector((state: RootState) => state.config.config)

  useEffect(() => {
    if (rid && rid !== 'maxstock') {
      navigate(keys.ROUTE_NAMES.NEWS_FEED)
    }
  }, [navigate, rid])

  const handleIframeLoad = () => {
    setIsLoading(false)
  }
  const getUrl = () => {
    switch (uid) {
      case '033750035':
        return 'https://forms.storee.ai/tables/233154210546043/243251829113048'
      case '302632377':
        return 'https://forms.storee.ai/tables/233154210546043/243251829113048'
      case '038207650':
        return 'https://forms.storee.ai/tables/233154210546043/243251829113048'
      default:
        return 'https://forms.storee.ai'
    }
  }

  return (
    <Box p="2rem" h="92vh" w="100%">
      {isLoading && <Loader />}
      <iframe src={getUrl()} width="100%" height="100%" title="jotform" onLoad={handleIframeLoad} />
    </Box>
  )
}

export default ExternalSite
