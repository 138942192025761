import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { Box } from '@chakra-ui/core'
import React, { useCallback } from 'react'
import { isRtlSelector } from 'redux/selectors/general'
interface ICreateMarkUpText {
  text: string
  initiator: number
  fontFamily?: string
  lineHeight?: string
  fontSize?: string
  fontWeight?: string
}
export const MarkUpText = ({ text, initiator, fontFamily, lineHeight, fontSize, fontWeight }: ICreateMarkUpText) => {
  const { translations } = useSelector((state: RootState) => state.config)
  const { locale } = useSelector((state: RootState) => state.general)
  const isRtl = useSelector(isRtlSelector)

  const handleStringSample = useCallback(
    (sample: string) => {
      return `${isRtl ? '‏' : '‎'}${translations[locale]?.[sample] || sample}`
    },
    [isRtl, locale, translations]
  )
  const decodeMessage = useCallback(
    (message: string) => {
      if (typeof message !== 'string') return ''

      return message
        ?.split(' ')
        ?.map((sample) => handleStringSample(sample))
        ?.join(' ')
    },
    [handleStringSample]
  )

  const formatTextWithLineBreaks = (message: string) => {
    return message.replace(/\n/g, '<br />')
  }

  return (
    <>
      {initiator === 1 ? (
        <>
          {decodeMessage(text)
            ?.split('/<(,|\n)*?>/g')
            ?.map((paragraph) => {
              return (
                <Box
                  key={paragraph}
                  dir="auto"
                  fontFamily={fontFamily}
                  lineHeight={lineHeight}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  dangerouslySetInnerHTML={{
                    __html: formatTextWithLineBreaks(paragraph),
                  }}
                />
              )
            })}
        </>
      ) : (
        <>
          {text?.split('/<(,|\n)*?>/g').map((paragraph) => {
            return (
              <Box
                as="span"
                key={paragraph}
                dir="auto"
                fontFamily={fontFamily}
                lineHeight={lineHeight}
                fontSize={fontSize}
                fontWeight={fontWeight}
                dangerouslySetInnerHTML={{
                  __html: formatTextWithLineBreaks(paragraph),
                }}
              />
            )
          })}
        </>
      )}
    </>
  )
}
