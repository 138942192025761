import { useToast } from '@chakra-ui/core'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { handlePutLikes } from 'redux/actions/feed'

interface UseLikedPostsProps {
  post_id: string
  created_by: string
  groups: string[]
  users: string[]
  tags: string[]
  liked: { [key: string]: boolean }
  myUID: string
  isSearchPage: boolean
  handleUpdatedViewedPost: () => void
}
export const useLikedPosts = ({
  post_id,
  created_by,
  groups,
  tags,
  users,
  liked,
  myUID,
  isSearchPage,
  handleUpdatedViewedPost,
}: UseLikedPostsProps) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const requireToLikeUser = Object.keys(liked)

  const isLikedPost = useMemo(
    () => created_by === myUID || liked[myUID] || !requireToLikeUser.includes(myUID),
    [created_by, liked, myUID, requireToLikeUser]
  )

  const isShowLikeButton = useMemo(
    () => created_by !== myUID && requireToLikeUser.includes(myUID!),
    [created_by, myUID, requireToLikeUser]
  )

  const likeHandler = useCallback(() => {
    if (isLikedPost) return
    try {
      dispatch(handlePutLikes(post_id, groups, users, tags, isSearchPage))
      handleUpdatedViewedPost()
    } catch (err) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [dispatch, groups, handleUpdatedViewedPost, isLikedPost, isSearchPage, post_id, tags, toast, users])

  return {
    isLikedPost,
    likeHandler,
    isShowLikeButton,
  }
}
