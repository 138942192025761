import { Avatar, Flex, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import React from 'react'
import storeeAvatar from 'assets/storee-icon.png'

export const FadeReportMessage = ({ text }: { text: string }) => {
  return (
    <Flex
      pos="fixed"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      h="400px"
      w="50%"
      bg="white"
      zIndex={2000}
      pointerEvents="auto"
      boxShadow="lg"
      borderRadius="20px"
      justifyContent="center"
      alignItems="center"
    >
      <Flex flexDir="column" alignItems="center">
        <Avatar src={storeeAvatar} size="xl" />
        <Text fontSize="35px" fontWeight="bold" mt="30px">
          <LocaleText text={text} />
        </Text>
      </Flex>
    </Flex>
  )
}
