/* eslint-disable react/no-children-prop */
import React from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Box, Flex, Image, Text } from '@chakra-ui/core'
import { setIsEmptyData, setRankingFilter } from 'redux/actions/ranking'
import { CustomNavLink } from './style'
import keys from 'constants/keys'
import useLocaleText from 'components/useLocaleText'
import { RootState } from 'constants/interfaces'
import { isRTL, isValidNumberString } from 'utils'
import LocaleText from 'components/LocaleText'
import { RankingDiff } from '../RankingSlider/RankingDiff'
import colors from 'constants/colors'
import strings from 'constants/strings'
import useAvatar from 'hooks/useAvatar'
import { useUserName } from 'hooks/useUserName'

interface IRankNavItem {
  kpi: any
  setIsShowDropDown: (value: boolean) => void
}

export const RankNavItem = ({ kpi, setIsShowDropDown }: IRankNavItem) => {
  const dispatch = useDispatch()
  const {
    retailConfig,
    config: { mappedRetailUsersByGroups, groups },
  } = useSelector((state: RootState) => state.config)
  const { rankingReports, kpisMediaData, isRankingLoading, kpisList } = useSelector((state: RootState) => state.ranking)
  const isDynamicKpi = Object.values(kpisList).find((item) => item.id === kpi?.id)?.type === 'dynamic_kpi'
  const rankTitle = useLocaleText(kpisMediaData[kpi?.id]?.title)
  const rankType = kpi?.id
  const rankIcon = kpisMediaData[kpi?.id]?.icon

  const { stores: currentRank } = rankingReports[kpi?.id] || {}

  const storeRank = useMemo(
    () => currentRank?.filter((rank: any) => !!rank.rank).sort((a: any, b: any) => a.rank - b.rank)?.[0],
    [currentRank]
  )

  const managersUid = mappedRetailUsersByGroups?.[storeRank?.group]?.[0]
  const managersAvatar = useAvatar(managersUid)
  const managersFullName = useUserName(managersUid)

  const slicedRankTitle = useMemo(() => {
    return rankTitle?.length > 38 ? `${rankTitle.slice(0, 38)} ...` : rankTitle
  }, [rankTitle])

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isRankingLoading) {
      event.preventDefault()
    } else {
      setIsShowDropDown(false)
      dispatch(setIsEmptyData(false))
      dispatch(setRankingFilter(0))
    }
  }

  const isRenderStoreProperties = useMemo(() => {
    return (
      (isDynamicKpi && storeRank && storeRank.diff !== null && storeRank.diff !== undefined) ||
      (storeRank && storeRank.target !== null && storeRank.target !== undefined) ||
      (storeRank && storeRank.performance !== null && storeRank.performance !== undefined)
    )
  }, [isDynamicKpi, storeRank])

  return (
    <CustomNavLink
      onClick={handleClick}
      to={keys.ROUTE_NAMES.RANKING(rankType?.toString())}
      children={({ isActive }) => {
        return (
          <Flex
            color={isActive ? 'black' : colors.greyDark}
            fontWeight={isActive ? '600' : '400'}
            px="20px"
            style={{
              position: 'relative',
              width: '100%',
              height: '70px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Flex flexDir="row" alignItems="center" justifyContent="space-between" w="100%" aria-label="rank">
              {retailConfig?.is_personalization_mode && (
                <Flex alignItems="center" style={{ columnGap: '10px' }} w="185px">
                  <Avatar src={managersAvatar} />
                  <Flex flexDir="column" fontSize="16px" fontFamily={'sans-serif'}>
                    {managersFullName !== undefined && (
                      <Text isTruncated maxW="125px">
                        {managersFullName}
                      </Text>
                    )}
                    <Text isTruncated maxW="125px">
                      {groups[storeRank?.group]?.name}
                    </Text>
                  </Flex>
                </Flex>
              )}

              <Flex flexDir={retailConfig?.is_personalization_mode ? 'column' : 'row'} alignItems="center">
                <Image w="50px" h="50px" src={rankIcon} />
                <Flex
                  mx="10px"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  aria-label="rank"
                  fontSize="16px"
                >
                  {!retailConfig?.is_personalization_mode && (
                    <Text isTruncated maxW="125px">
                      {groups[storeRank?.group]?.name}
                    </Text>
                  )}

                  <Text textAlign="center" dir={isRTL(slicedRankTitle) ? 'rtl' : 'ltr'}>
                    {slicedRankTitle}
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDir="column" alignItems="flex-end" w="185px">
                <Box fontSize="18px" dir={'ltr'}>
                  {storeRank?.value}{' '}
                  {isValidNumberString(storeRank?.value) && (
                    <Text as="span" fontSize="18px">
                      <LocaleText text="general_units" />
                    </Text>
                  )}
                </Box>
                {isRenderStoreProperties ? (
                  <Flex justifyContent="flex-end" flexGrow={1} alignItems="center" w="100%">
                    <Flex flexDir="column">
                      {isDynamicKpi && storeRank.diff !== null && storeRank.diff !== undefined && (
                        <Flex justifyContent="flex-end">
                          <RankingDiff diff={storeRank.diff} />
                          {storeRank.diff !== strings.RANK_DIFF_STRING && (
                            <Text fontSize="14px">
                              <LocaleText text="ranking_diff_from_last_month" />
                            </Text>
                          )}
                        </Flex>
                      )}

                      {storeRank.target !== null && storeRank.target !== undefined && (
                        <Text fontSize="14px">
                          <LocaleText text="general_target" />
                          <Text as="span">: {storeRank.target} </Text>
                          {isValidNumberString(storeRank.target) && <LocaleText text="general_units" />}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                ) : (
                  !storeRank?.value && (
                    <Text fontSize="14px">
                      <LocaleText text="ranking_learn_more" />
                    </Text>
                  )
                )}
              </Flex>
            </Flex>
          </Flex>
        )
      }}
    />
  )
}
