import { IKpiList, IKpiMediaData, IRankingReportObject, IDataFilterValue } from './../../constants/interfaces'
import { ITag, RootState } from 'constants/interfaces'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { AxiosError } from 'axios'
import colors from 'constants/colors'

interface IGetKpisArgs {
  rankingTag?: any
  rankingName?: string
  kpis_list?: boolean
  kpis_data?: boolean
  force_tag_data?: boolean
  kpiId?: string
  dynamic_kpi_ids?: string[]
  competition_kpi_ids?: string[]
  isAlternativeFilter?: boolean
  from_ts?: string
  to_ts?: string
  isCustomDate?: boolean
  dataFilter?: IDataFilterValue
  firstRequest?: boolean
  kpisFilterListWillUpdate?: boolean
  isChildLevelTag?: boolean
}
export interface IHandleDefaultKpiCall {
  data: IKpisResponse
  rankingFilterList: ITag[]
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  rankingReports: IRankingReportObject
  dataFilter?: IDataFilterValue
  kpiListHeaderView: IKpiList[]
}

export interface IKpisListItem {
  competition_type?: string
  from_ts?: string
  id: string
  item_type?: string
  title?: string
  to_ts?: string
  type: string
}
export interface IKpisResponse {
  kpis_list: IKpisListItem[] | undefined
  kpis_data: any
  kpis_data_ids: string[]
  dates: string
}
export interface IHandleKpisMediaData {
  data: IKpisResponse
  icons: string[]
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  color: string
}
export interface IhandleSpecificKpi {
  from_ts?: string
  to_ts?: string
  data: IKpisResponse
  rankingReports: IRankingReportObject
  kpisList: IKpiList[]
  rankingFilterList: ITag[]
  rankingFilterMode: number
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  isCustomDate?: boolean
  dataFilter?: IDataFilterValue
}
interface IHandleSpeceficKpiByTag {
  from_ts?: string
  to_ts?: string
  data: IKpisResponse
  kpisList: IKpiList[]
  rankingReports: IRankingReportObject
  rankingName: string
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  dataFilter?: IDataFilterValue
}
interface IHanldeKpisFilterList {
  isTagData: boolean
  tags: ITag[]
  rankingTags: string[]
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
}
export interface IBuildRankingReport {
  dataRows: any[][]
  dataColumns: any[]
  rankingTypes: IKpiList[]
  name: string
  dates?: any
  dataFilter?: IDataFilterValue
}
interface IKpisListViews {
  kpiListResp: IKpisListItem[]
  kpisDataIdsResp: string[]
  kpiListHeaderView: IKpiList[]
  kpisList: IKpiList[]
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
}

export const actionTypes = {
  SET_IS_RANKING_LOADING: '[RANKING] SET_IS_RANKING_LOADING',
  SET_RANKING_REPORTS: '[RANKING] SET_RANKING_REPORTS',
  SET_RANKING_SORT: '[RANKING] SET_RANKING_SORT',
  SET_RANKING_FILTER: '[RANKING] SET_RANKING_FILTER',
  SET_IS_REPORT_BLOCKED: '[RANKING] SET_IS_REPORT_BLOCKED',
  SET_RANKING_FILTER_LIST: 'SET_RANKING_FILTER_LIST',
  SET_KPIS_LIST: 'SET_KPIS_LIST',
  SET_KPIS_MEDIA_DATA: 'SET_KPIS_MEDIA_DATA',
  SET_KPIS_LIST_HEADER_VIEW: 'SET_KPIS_LIST_HEADER_VIEW',
  SET_KPIS_LIST_DROPDOWN_VIEW: 'SET_KPIS_LIST_DROPDOWN_VIEW',
  SET_KPIS_DATES: 'SET_KPIS_DATES',
  SET_IS_EMPTY_DATA: ' SET_IS_EMPTY_DATA',
  SET_WAS_DYNAMIC_KPI_DATE_UPDATED: 'SET_WAS_DYNAMIC_KPI_DATE_UPDATED',
}
export const setWasDynamicKpiDateUpdated = (wasDynamicKpiDateUpdated: boolean) => ({
  type: actionTypes.SET_WAS_DYNAMIC_KPI_DATE_UPDATED,
  payload: wasDynamicKpiDateUpdated,
})
export const setIsEmptyData = (isEmpty: boolean) => ({
  type: actionTypes.SET_IS_EMPTY_DATA,
  payload: isEmpty,
})
export const setKpisDates = (dates: string) => ({
  type: actionTypes.SET_KPIS_DATES,
  payload: dates,
})
export const setKpisListHeaderView = (filteredKpiList: IKpiList[]) => ({
  type: actionTypes.SET_KPIS_LIST_HEADER_VIEW,
  payload: filteredKpiList,
})

export const setKpisListDropDownView = (filteredKpiList: IKpiList[]) => ({
  type: actionTypes.SET_KPIS_LIST_DROPDOWN_VIEW,
  payload: filteredKpiList,
})

export const setKpisMediaData = (data: IKpiMediaData) => ({
  type: actionTypes.SET_KPIS_MEDIA_DATA,
  payload: data,
})
export const setKpisList = (list: IKpiList[]) => ({
  type: actionTypes.SET_KPIS_LIST,
  payload: list,
})
export const setRankingFilterList = (filterItems: ITag[]) => ({
  type: actionTypes.SET_RANKING_FILTER_LIST,
  payload: filterItems,
})

export const setRankingReports = (rankingReports: IRankingReportObject) => ({
  type: actionTypes.SET_RANKING_REPORTS,
  payload: rankingReports,
})

export const setRankingSort = (rankingSort: number) => ({
  type: actionTypes.SET_RANKING_SORT,
  payload: rankingSort,
})

export const setRankingFilter = (rankingFilter: number) => ({
  type: actionTypes.SET_RANKING_FILTER,
  payload: rankingFilter,
})

export const setIsRankingBlocked = (isRankingBlocked: boolean) => ({
  type: actionTypes.SET_IS_REPORT_BLOCKED,
  payload: isRankingBlocked,
})

export const setIsRankingLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_RANKING_LOADING,
  payload: isLoading,
})
const filterKPIListByRankingType = (kpisList: IKpiList[]): IKpiList[] => {
  const rankingTypes = String(kpisList?.map((r) => r.type))
  return kpisList.filter((kpi) => {
    if (kpi.type === 'dynamic_kpi') {
      return rankingTypes?.includes(kpi.id)
    }
    return true
  })
}

const buildRankingReport = ({ dataRows, dataColumns, rankingTypes, name, dates, dataFilter }: IBuildRankingReport) => {
  return rankingTypes.reduce((acc, rank) => {
    return {
      ...acc,
      [rank.id]: {
        tagName: name,
        ...(dates && Object.keys(dates).length > 0 ? { dates: dates } : {}),
        ...(dataFilter ? { dataFilter: dataFilter } : {}),
        stores: dataRows.reduce((acc, row) => {
          let obj = {}
          let filteredObj = {}
          const [group, name, ...values] = dataColumns
          const groupName = [group, name]
          const newValues = values.filter((value) => value.split('_').pop() === rank.id)
          const newDataColumn = [...groupName, ...newValues]

          for (let i = 0; i < row.length; i++) {
            const key = dataColumns[i]
            obj[key] = row[i]
          }

          filteredObj = Object.keys(obj).reduce((value, key) => {
            if (newDataColumn.includes(key)) {
              return {
                group: obj['group_id'],
                name: obj['name'],
                diff: obj[`diff_${rank.id}`],
                rank: obj[`rank_${rank.id}`],
                value: obj[`value_${rank.id}`],
                performance: obj[`performance_${rank.id}`],
                target: obj[`target_${rank.id}`],
              }
            }

            return value
          }, {})

          acc.push(filteredObj)
          return acc
        }, []),
      },
    }
  }, {})
}

const handleKpisMediaData = ({ data, icons, dispatch, color }: IHandleKpisMediaData) => {
  if (data.dates) dispatch(setKpisDates(data.dates))
  if (!data.kpis_list) return
  else {
    try {
      const kpis = data.kpis_list?.reduce((acc, kpi, index) => {
        acc[kpi.id] = {
          title: kpi.title,
          type: kpi.type,
          icon: icons[index % icons.length],
          color: color,
        }

        return acc
      }, {})

      if (Object.keys(kpis).length > 0) {
        dispatch(setKpisMediaData(kpis))
      }
    } catch (error) {
      console.log(`handleKpisMediaData failed due ${error}`)
    }
  }
}

const handleDefaultKpis = ({
  data,
  rankingFilterList,
  dispatch,
  rankingReports,
  dataFilter,
  kpiListHeaderView,
}: IHandleDefaultKpiCall) => {
  if (!data.kpis_list) return
  else {
    try {
      if (data.kpis_data.data_rows.length > 0) {
        const rank = buildRankingReport({
          dataRows: data.kpis_data.data_rows ?? [],
          dataColumns: data.kpis_data.data_columns ?? [],
          rankingTypes: kpiListHeaderView || [],
          name: typeof rankingFilterList[0] !== 'string' ? rankingFilterList[0].name : rankingFilterList[0],
          dataFilter,
        })

        dispatch(setRankingReports({ ...rankingReports, ...rank }))
      }
    } catch (error) {
      console.log(`handleDefaultKpiCall failed due ${error}`)
    }
  }
}

const handleSpecificKpi = ({
  from_ts,
  to_ts,
  data,
  rankingReports,
  kpisList,
  rankingFilterList,
  dispatch,
  isCustomDate,
  dataFilter,
}: IhandleSpecificKpi) => {
  const filteredKpisListByDataIds = kpisList.filter((item: any) => data.kpis_data_ids.includes(item.id))

  const rank = buildRankingReport({
    dataRows: data.kpis_data.data_rows ?? [],
    dataColumns: data.kpis_data.data_columns ?? [],
    rankingTypes: filteredKpisListByDataIds || [],
    name: typeof rankingFilterList[0] !== 'string' ? rankingFilterList[0].name : rankingFilterList[0],
    dates: from_ts && to_ts && isCustomDate ? { range: data.dates, from_ts, to_ts } : {},
    dataFilter,
  })

  dispatch(setRankingReports({ ...rankingReports, ...rank }))
}

const handleSpecificKpiByTag = ({
  from_ts,
  to_ts,
  data,
  kpisList,
  rankingReports,
  rankingName,
  dispatch,
  dataFilter,
}: IHandleSpeceficKpiByTag) => {
  const filteredKpisListByDataIds = kpisList?.filter((item: any) => data.kpis_data_ids.includes(item.id))
  const rank = buildRankingReport({
    dataRows: data.kpis_data.data_rows ?? [],
    dataColumns: data.kpis_data.data_columns ?? [],
    rankingTypes: filteredKpisListByDataIds || [],
    name: rankingName,
    dates: from_ts && to_ts ? { range: data.dates, from_ts, to_ts } : {},
    dataFilter,
  })

  dispatch(setRankingReports({ ...rankingReports, ...rank }))
}

export const getRanking =
  ({
    from_ts,
    to_ts,
    rankingTag,
    rankingName,
    kpis_list,
    kpis_data,
    force_tag_data,
    dynamic_kpi_ids,
    competition_kpi_ids,
    isCustomDate,
    dataFilter,
    firstRequest,
    kpisFilterListWillUpdate,
  }: IGetKpisArgs): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsRankingBlocked(false))
      dispatch(setIsRankingLoading(true))
      const rankingData = getState().config.retailConfig?.ranking_data
      const {
        ranking: { rankingReports, rankingFilterList, kpisList, rankingFilterMode },
        config: {
          retailConfig,
          activeGroupID,
          config: { tags },
        },
        general: { theme },
      } = getState()
      const color = theme?.elementsColor ?? colors.pinkDark
      const icons = rankingData?.icons || []
      const rankingTags = retailConfig?.ranking_tags || []
      const defaultKpis = kpis_data && kpis_list
      const specificKpi = kpis_data && !kpis_list
      const specificKpiByTag = kpis_data && !kpis_list && rankingName
      const sub_retail_id = retailConfig?.sub_retail_id
      let params = {
        kpis_data,
        kpis_list,
        force_tag_data,
        from_ts,
        to_ts,
        group: activeGroupID,
        ...(dataFilter ? { data_filter: dataFilter.value } : {}),
        ...(sub_retail_id ? { sub_retail_id } : {}),
        ...(rankingTag ? { tag: rankingTag } : {}),
        ...(dynamic_kpi_ids && dynamic_kpi_ids.length > 0 ? { dynamic_kpi_ids: dynamic_kpi_ids.join(',') } : {}),
        ...(competition_kpi_ids && competition_kpi_ids.length > 0
          ? { competition_kpi_ids: competition_kpi_ids.join(',') }
          : {}),
      }
      let uniqueKpisList: IKpiList[] = []
      let uniqueKpisListIncludesDataIds: IKpiList[] = []
      let uniqueKpisListNotIncludesDataIds: IKpiList[] = []
      let kpisFilterList: ITag[] | any = []

      const { data } = await httpAuth.get(endpoints.getRanking, {
        params: params,
      })

      handleKpisMediaData({ data, icons, dispatch, color })

      if (data.kpis_list.length > 0 && defaultKpis) {
        const splittedKpisDate = data.dates.split(' - ')
        uniqueKpisList = data.kpis_list
          .sort((a: any, b: any) => a.id - b.id)
          .map((kpi: any) => {
            if (kpi.type === 'dynamic_kpi') {
              const from_ts = splittedKpisDate[0]
              const to_ts = splittedKpisDate[1]
              return {
                ...kpi,
                from_ts,
                to_ts,
              }
            } else {
              return kpi
            }
          })

        uniqueKpisListIncludesDataIds =
          data.kpis_data_ids.length !== 0
            ? uniqueKpisList.filter((item) => data.kpis_data_ids.includes(item.id))
            : uniqueKpisList.slice(0, 2)
        uniqueKpisListNotIncludesDataIds =
          data.kpis_data_ids.length !== 0
            ? uniqueKpisList.filter((item) => !data.kpis_data_ids.includes(item.id))
            : uniqueKpisList.slice(2)

        dispatch(setKpisList(uniqueKpisList))
        dispatch(setKpisListHeaderView(uniqueKpisListIncludesDataIds))
        dispatch(setKpisListDropDownView(uniqueKpisListNotIncludesDataIds))
      }
      if (kpisFilterListWillUpdate !== false) {
        const tagsDict: { [key: string]: ITag } = {}
        tags.forEach((item) => {
          tagsDict[item.sk] = item
        })

        const found: ITag[] = []
        const notFound: string[] = []

        rankingTags.forEach((item) => {
          if (tagsDict[item]) {
            found.push(tagsDict[item])
          } else {
            notFound.push(item)
          }
        })
        found.reverse()
        kpisFilterList = [...notFound, ...found]
        //@ts-ignore
        dispatch(setRankingFilterList(kpisFilterList))
      }

      const isDataNotEmtpy =
        data.kpis_data.data_columns &&
        data.kpis_data.data_rows &&
        data.kpis_data.data_columns.length > 0 &&
        data.kpis_data.data_rows.length > 0

      if (isDataNotEmtpy) {
        if (defaultKpis) {
          handleDefaultKpis({
            data,
            //@ts-ignore
            rankingFilterList: kpisFilterList,
            rankingReports,
            dispatch,
            dataFilter,
            kpiListHeaderView: uniqueKpisListIncludesDataIds,
          })
        }

        if (specificKpi) {
          handleSpecificKpi({
            from_ts,
            to_ts,
            data,
            rankingReports,
            kpisList,
            rankingFilterList,
            rankingFilterMode,
            dispatch,
            isCustomDate,
            dataFilter,
          })
        }

        if (specificKpiByTag) {
          handleSpecificKpiByTag({ from_ts, to_ts, data, kpisList, rankingReports, rankingName, dispatch, dataFilter })
        }
      } else if (!firstRequest) {
        dispatch(setIsEmptyData(true))
      }
    } catch (err) {
      dispatch(setIsEmptyData(true))
      console.log(`getRanking failed due ${err}`)

      const error: AxiosError | any = err
      if (error?.response?.status === 403) {
        dispatch(setIsRankingBlocked(true))
      }
    } finally {
      dispatch(setIsRankingLoading(false))
    }
  }
