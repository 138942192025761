import { useCallback, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { updatePostViews } from 'redux/actions/feed'

interface UseViewedPostsProps {
  created_by: string
  groups: string[]
  isSearchPage: boolean
  myUID: string
  post_id: string
  users: string[]
  tags: string[]
  viewed: { [key: string]: boolean }
  liked: { [key: string]: boolean }
  isDoublePost?: boolean
}
export const useViewedPosts = ({
  created_by,
  groups,
  isSearchPage,
  myUID,
  post_id,
  users,
  tags,
  viewed,
  liked,
  isDoublePost,
}: UseViewedPostsProps) => {
  const dispatch = useDispatch()
  const [ref, inView] = useInView({
    threshold: 0.5,
  })
  const requireToUpdateViews = useMemo(() => Object.keys(viewed), [viewed])
  const isViewedPost = useMemo(
    () => created_by === myUID || viewed[myUID] || !requireToUpdateViews.includes(myUID),
    [created_by, myUID, requireToUpdateViews, viewed]
  )

  const requireToLikeUser = Object.keys(liked)

  const isLikedPost = useMemo(
    () => created_by === myUID || liked[myUID] || !requireToLikeUser.includes(myUID),
    [created_by, liked, myUID, requireToLikeUser]
  )

  const handleUpdatedViewedPost = useCallback(() => {
    if (isViewedPost || isLikedPost || !inView) return
    dispatch(updatePostViews(post_id, groups, users, tags, isSearchPage))
  }, [dispatch, groups, inView, isLikedPost, isSearchPage, isViewedPost, post_id, tags, users])

  useEffect(() => {
    if (isViewedPost || isLikedPost || !inView) return
    if (isDoublePost) {
      handleUpdatedViewedPost()
      return
    }
    let start: number | null = null
    let progress = 0
    let animationFrameId: number | null = null

    const animate = (timestamp: number) => {
      if (!start) start = timestamp
      const elapsed = timestamp - start

      progress = (elapsed / 4000) * 100

      if (progress < 100) {
        animationFrameId = requestAnimationFrame(animate)
      } else {
        handleUpdatedViewedPost()
      }
    }

    if (inView && !isViewedPost && !isLikedPost) {
      animationFrameId = requestAnimationFrame(animate)
    }

    return () => {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, post_id, isLikedPost, isViewedPost])

  return {
    ref,
    inView,
    handleUpdatedViewedPost,
    requireToUpdateViews,
    isViewedPost,
  }
}
