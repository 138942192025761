/* eslint-disable no-async-promise-executor */
import { httpAuth } from '../../config/apiClient'
import { mediaUrl } from '../../constants/endpoints'
import endpoints from '../../constants/endpoints'
import { Post } from '../reducers/feed'
import copy from 'fast-copy'
import isSolid from 'is-solid'
import { handleError, updateLocalStorageItem } from 'utils'
import { logoutHandler } from './auth'
import { ThunkAction } from 'redux-thunk'
import { RootState } from 'constants/interfaces'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'
import { getUnixTime, sub } from 'date-fns'
import { setResponseStatus } from './general'
import keys from 'constants/keys'
import { setComments, setCommentsCounter, setViewedCommentsIds, updateViews } from './comments'
import { setIsLoading } from './pops'

export const CHAT = 2
export const POST = 0

export interface IHandleUpdatePost {
  title: string
  images?: Array<string>
  videos?: Array<string>
  taskRefId?: string | null
  postId: string
  groups?: string[]
  tags?: string[]
  groupsToDelete?: string[]
  newGroupsToPublish?: string[]
  newTagsToPublish?: string[]
  tagsToDelete?: string[]
  createdAtTs: number
  rasId?: string
  files?: Array<string>
  audios?: Array<string>
  isSearchPage?: boolean
  groupsToPayload?: string[]
  users?: string[]
  newUsersToPublish?: string[]
  usersToDelete?: string[]
  isChat?: boolean
  postType: number
  admins?: string[]
}

interface ISetPosts {
  posts: Array<Post>
  isRefresh?: boolean
  newPost?: boolean
  isDeleting?: boolean
  isNewLike?: boolean
}

export const actionTypes = {
  SET_POSTS: '[FEED] SET_POSTS',
  SET_CHATS: '[FEED] SET_CHATS',
  SET_FETCHING: '[FEED] SET_FETCHING',
  SET_HAS_MORE_POSTS: '[FEED] SET_HAS_MORE_POSTS',
  SET_HAS_MORE_CHATS: '[FEED] SET_HAS_MORE_CHATS',
  RESET_FEED: '[FEED] RESET_FEED',
  SET_SEARCH_POST: '[FEED] SET_SEARCH_POST',
  SET_IS_SEARCH_FETCHING: '[FEED] SET_IS_SEARCH_FETCHING',
  SET_IS_ALL_POSTS_COLLAPSED: '[FEED] SET_IS_ALL_POSTS_COLLAPSED',
  SET_IS_SHOW_ALL_MY_POSTS: '[FEED] SET_IS_SHOW_ALL_MY_POSTS',
  SET_SELECTED_POST: '[FEED] SET_SELECTED_POST',
  SET_SELECTED_CHAT: '[FEED] SET_SELECTED_CHAT',
  SET_COMMENTS: '[FEED] SET_COMMENTS',
  SET_COMMENT_REPLIES: '[FEED] SET_COMMENT_REPLIES',
  SET_COMMENTS_COUNTER: '[FEED] SET_COMMENTS_COUNTER',
  SET_CONSOLIDATION_FILTER_OPTIONS: '[FEED] SET_CONSOLIDATION_FILTER_OPTIONS',
  SET_RESPONSE_STATUS: '[FEED] SET_RESPONSE_STATUS',
  SET_CHATS_FETCHING: '[FEED] SET_CHATS_FETCHING',
  SET_PREFETCHED_CHATS: '[FEED] SET_PREFETCHED_CHATS',
}
type ISetChats = {
  chats: Post[]
  isRefresh?: boolean
}
export const setChats = ({ chats, isRefresh }: ISetChats) => ({
  type: actionTypes.SET_CHATS,
  chats,
  isRefresh,
})
export const setHasMoreChats = (hasMoreChats: boolean) => ({
  type: actionTypes.SET_HAS_MORE_CHATS,
  payload: hasMoreChats,
})

export const setConsolidationFilter = (ids: string[]) => ({
  type: actionTypes.SET_CONSOLIDATION_FILTER_OPTIONS,
  payload: ids,
})
export const setSelectedPost = (postID: string) => ({
  type: actionTypes.SET_SELECTED_POST,
  payload: postID,
})
export const setSelectedChat = (chatID: string | null) => ({
  type: actionTypes.SET_SELECTED_POST,
  payload: chatID,
})

export const setIsShowAllMyPosts = (next: boolean) => ({
  type: actionTypes.SET_IS_SHOW_ALL_MY_POSTS,
  payload: next,
})
export const setIsAllPostsCollapsed = (isCollapsed: boolean) => ({
  type: actionTypes.SET_IS_ALL_POSTS_COLLAPSED,
  payload: isCollapsed,
})
export const resetFeedState = () => ({
  type: actionTypes.RESET_FEED,
})

export const setFetching = (isFetching: boolean) => ({
  type: actionTypes.SET_FETCHING,
  fetching: isFetching,
})
export const setChatsFetching = (isFetching: boolean) => ({
  type: actionTypes.SET_CHATS_FETCHING,
  fetching: isFetching,
})

export const setHasMorePosts = (hasMorePosts: boolean) => ({
  type: actionTypes.SET_HAS_MORE_POSTS,
  payload: hasMorePosts,
})

export const setIsSearchFetching = (isFetching: boolean) => ({
  type: actionTypes.SET_IS_SEARCH_FETCHING,
  payload: isFetching,
})

export const setSearchPosts = (posts: Array<Post>) => ({
  type: actionTypes.SET_SEARCH_POST,
  payload: posts,
})

export const setPrefetchedChats = (chat: Post) => ({
  type: actionTypes.SET_PREFETCHED_CHATS,
  payload: chat,
})

interface IGetSearchPost {
  search?: string
  is_contain_media?: boolean
  created_by?: string
  date: {
    from_ts?: number
    to_ts?: number
  }
}

export const getSearchPost =
  (params?: IGetSearchPost): ThunkAction<void, RootState, IGetSearchPost, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(setIsSearchFetching(true))
    try {
      const groupID = getState().config.activeGroupID
      const { data } = await httpAuth.get(endpoints.searchPosts, {
        params: {
          group: groupID,
          from_ts: !params?.date?.from_ts ? getUnixTime(sub(new Date(), { days: 90 })) : params.date.from_ts,
          to_ts: !params?.date?.to_ts ? getUnixTime(new Date()) : params.date.to_ts,
          ...(params?.created_by ? { created_by: params.created_by } : {}),
          ...(params?.is_contain_media ? { is_contain_media: params.is_contain_media } : {}),
          ...(params?.search ? { query: params.search } : {}),
        },
      })
      dispatch(setSearchPosts(data.posts))
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setIsSearchFetching(false))
    }
  }

export const setPosts = ({ posts, isRefresh, newPost, isDeleting, isNewLike }: ISetPosts) => ({
  type: actionTypes.SET_POSTS,
  posts,
  isRefresh,
  newPost,
  isDeleting,
  isNewLike,
})

interface IGetPosts {
  ts?: string
  postIds?: string[]
  isRefresh?: boolean
  is_init?: boolean
  groups?: string[]
  type?: number
}

export const handleGetPosts =
  ({
    ts,
    postIds,
    isRefresh,
    is_init,
    groups,
    type = POST,
  }: IGetPosts): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(type === POST ? setFetching(true) : setChatsFetching(true))
    const {
      config: { activeGroupID },
      feed: { isShowAllMyPosts },
    } = getState()
    try {
      if (!activeGroupID) return
      const params = {
        ...(type === CHAT ? { type } : {}),
        ...(ts ? { from_ts: ts } : {}),
        ...(!isShowAllMyPosts ? { group: activeGroupID } : {}),
        ...(postIds && postIds?.length > 0 ? { post_ids: postIds.join(',') } : {}),
        ...(is_init ? { is_init: true } : {}),
        ...(groups && groups?.length > 0 ? { groups: groups.join(',') } : {}),
      }
      const { data } = await httpAuth.get(endpoints.getPosts, { params: params })
      if (isSolid(data.posts)) {
        dispatch(
          type === POST
            ? setPosts({
                posts: data.posts,
                isRefresh,
              })
            : setChats({ chats: data.posts, isRefresh })
        )
      }
      if (!isSolid(data.posts)) {
        dispatch(type === POST ? setHasMorePosts(false) : setHasMoreChats(false))
      }
      return data.posts
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      return Promise.reject(error)
    } finally {
      dispatch(type === POST ? setFetching(false) : setChatsFetching(false))
    }
  }

export const handleGetSinglePost =
  ({ postId, type }: { postId: string; type: number }): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(type === POST ? setFetching(true) : setChatsFetching(true))

    try {
      const params = {
        post_ids: postId,
        is_init: true,
        type: type,
      }
      const { data } = await httpAuth.get(endpoints.getPosts, { params: params })
      if (!data.posts.length) return false

      dispatch(setPrefetchedChats(data.posts[0]))
      return true
    } catch (err) {
      const error: AxiosError | any = err
      dispatch(type === POST ? setFetching(false) : setChatsFetching(false))
      if (error?.response?.status) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      return false
    } finally {
      dispatch(type === POST ? setFetching(false) : setChatsFetching(false))
    }
  }

const addNewPost = (posts: Post[], newPost: Post) => {
  let indexToInsert = -1
  for (let i = posts.length - 1; i >= 0; i--) {
    if (posts[i]?.is_self_pin || posts[i]?.is_manager_pin) {
      indexToInsert = i
      break
    }
  }

  if (indexToInsert === -1) {
    posts.unshift(newPost)
  } else {
    posts.splice(indexToInsert + 1, 0, newPost)
  }

  return posts
}

interface IHandleCreatePost {
  content?: string
  images?: string[]
  refId?: string | null
  groups?: string[]
  tags?: string[]
  users?: string[]
  files?: string[]
  videos?: string[]
  audios?: string[]
}
export const handleCreatePost =
  ({
    content,
    images,
    refId,
    groups,
    tags,
    users,
    files,
    videos,
    audios,
  }: IHandleCreatePost): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      feed: { posts },
    } = getState()

    dispatch(setFetching(true))
    try {
      const postData = {
        title: content ? content : null,
        type: refId !== null ? keys.POST_TYPE.LINKED : keys.POST_TYPE.REGULAR,
        images: images && images.length ? images : null,
        videos: videos && videos.length ? videos : null,
        ref_id: refId ?? null,
        groups,
        users,
        tags,
        files: files && files.length ? files : null,
        audios: audios?.length ? audios : null,
      }

      const { data } = await httpAuth.post(endpoints.createPost, postData)
      if (data) {
        dispatch(setResponseStatus(true))

        dispatch(
          setPosts({
            posts: addNewPost(posts, { ...data.post, is_new: true }),
            newPost: true,
          })
        )
      }
      return true
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      dispatch(setResponseStatus(false))
      return false
    } finally {
      dispatch(setFetching(false))
    }
  }

function prepareImages(images: string[]) {
  const newImages = images.filter((image) => !image.includes(mediaUrl))
  const oldImages = images.filter((image) => image.includes(mediaUrl))
  return [...newImages, ...oldImages]
}

export const handleUpdatePost =
  ({
    title,
    images,
    taskRefId,
    postId,
    users,
    tags,
    newUsersToPublish,
    usersToDelete,
    groupsToDelete,
    newGroupsToPublish,
    newTagsToPublish,
    tagsToDelete,
    files,
    audios,
    videos,
    isSearchPage,
    createdAtTs,
    groupsToPayload,
    postType,
    admins,
    isChat = false,
  }: IHandleUpdatePost): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      feed: { posts, chats, searchPosts },
      config: { userWritePermittedContext },
      config: {
        config: { rid },
      },
    } = getState()
    const oldPosts = isSearchPage ? searchPosts : isChat ? chats : posts

    const postOriginGroupNames: string[] =
      groupsToPayload?.map((group) => userWritePermittedContext?.groups?.[group]?.name) ?? []

    dispatch(setFetching(true))

    try {
      const imagesToUpload = images ? prepareImages(images) : []
      const postData = {
        title: title || null,
        type: postType,
        images: imagesToUpload ?? [],
        videos: videos ?? [],
        post_id: postId,
        files: files ?? [],
        ref_id: taskRefId ?? null,
        rid,
        audios: audios ?? [],
        groups: groupsToPayload ?? [],
        users: users ?? [],
        tags: tags ?? [],
        new_users_to_publish: newUsersToPublish ?? [],
        users_to_delete: usersToDelete ?? [],
        groups_to_delete: groupsToDelete ?? [],
        new_groups_to_publish: newGroupsToPublish ?? [],
        new_tags_to_publish: newTagsToPublish ?? [],
        tags_to_delete: tagsToDelete ?? [],
        created_at_ts: createdAtTs,
        admins: admins ?? [],
      }

      await httpAuth.put(endpoints.createPost, postData)

      let nextPosts = copy(oldPosts).map((post: any) => {
        let nextPost
        if (post.post_id === postId) {
          post.post_origin_group_names = postOriginGroupNames
          nextPost = {
            ...post,
            ...postData,
          }
        } else {
          nextPost = post
        }
        return nextPost
      })

      if (isSearchPage) {
        dispatch(setSearchPosts(nextPosts))
      } else {
        dispatch(
          isChat
            ? setChats({ chats: nextPosts, isRefresh: true })
            : setPosts({
                posts: nextPosts,
                newPost: true,
              })
        )
      }
      dispatch(setFetching(false))
      if (!isChat) dispatch(setResponseStatus(true))
      return true
    } catch (error) {
      console.error('Update post failed:', error)
      dispatch(setResponseStatus(false))
      dispatch(setFetching(false))
      return false
    }
  }

interface IDeletePost {
  postId: string
  isSearchPage?: boolean
  isChat?: boolean
  isDeletedByAdmin: boolean
}
export const handleDeletePost =
  ({ postId, isSearchPage = false, isChat = false, isDeletedByAdmin }: IDeletePost) =>
  async (dispatch: any, getState: any) => {
    const {
      feed: { posts, chats, searchPosts },
      config: {
        config: { rid },
      },
    } = getState()

    const prevPosts = isSearchPage ? searchPosts : isChat ? chats : posts
    const currentPost = prevPosts.find((post: Post) => post.post_id === postId)
    const groups = isDeletedByAdmin ? currentPost?.post_origin_groups : currentPost?.groups
    const users = isDeletedByAdmin ? currentPost?.post_origin_users : currentPost?.users
    const tags = isDeletedByAdmin ? currentPost?.post_origin_tags : currentPost?.tags
    try {
      const nextPosts = copy(prevPosts).filter((post: any) => post.post_id !== postId)

      if (isSearchPage) {
        dispatch(setSearchPosts(nextPosts))
      } else {
        dispatch(
          isChat
            ? setChats({ chats: nextPosts, isRefresh: true })
            : setPosts({
                posts: nextPosts,
                isDeleting: true,
              })
        )
      }
      await httpAuth.delete(endpoints.createPost, {
        data: {
          post_id: postId,
          rid,
          groups,
          users,
          tags,
        },
      })
    } catch (err) {
      const error: AxiosError | any = err
      dispatch(
        isChat
          ? setChats({ chats: chats, isRefresh: true })
          : setPosts({
              posts: posts,
              isDeleting: true,
            })
      )
      if (error?.response?.status) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      return Promise.reject(error)
    }
  }

export const handlePutLikes =
  (postId: string, groups: string[], users: string[], tags: string[], isSearchPage = false) =>
  async (dispatch: any, getState: any) => {
    const {
      feed: { posts, searchPosts },
      config: {
        config: { myUID },
      },
    } = getState()

    const oldPosts = isSearchPage ? searchPosts : posts

    try {
      const url = endpoints.likes(postId)

      const nextPosts = copy(oldPosts).map((post: any) => {
        if (post.post_id === postId) {
          ++post.likes_counter
          post.liked[myUID] = true
        }
        return post
      })
      httpAuth.put(url, {
        post_id: postId,
        groups: groups,
        users: users,
        tags: tags,
      })
      if (isSearchPage) {
        dispatch(setSearchPosts(nextPosts))
      } else {
        dispatch(setPosts({ posts: nextPosts, isNewLike: true }))
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      return Promise.reject(error)
    }
  }

interface IPingUser {
  post_id: string
  users_to_ping: { [key: string]: { context_id: string; status: boolean } }
  isSearchPage: boolean
  groups: string[]
  users: string[]
  tags: string[]
}
export const pingUser =
  ({
    post_id,
    groups,
    users,
    tags,
    users_to_ping,
    isSearchPage,
  }: IPingUser): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { posts, searchPosts } = getState().feed
      const oldPosts = isSearchPage ? searchPosts : posts
      // const groups = Object.keys(pings).map((key) => key)
      const post = oldPosts.find((post) => post.post_id === post_id)
      if (post) {
        const params = {
          post_id,
          groups,
          users,
          tags,
          users_to_ping,
        }

        const newPosts: any = oldPosts.map((post) => {
          if (post.post_id === post_id) {
            const ping = { ...post.ping }
            Object.keys(users_to_ping).forEach((key) => {
              ping[key] = { status: true, context_id: ping[key].context_id }
            })

            return { ...post, ping }
          } else {
            return post
          }
        })

        if (isSearchPage) {
          dispatch(setSearchPosts(newPosts))
        } else {
          dispatch(setPosts({ posts: newPosts, isRefresh: true }))
        }
        await httpAuth.post(endpoints.pingUsers, params)
      }
    } catch (error) {
      console.log(error)
    }
  }
interface IPinManager {
  post_id: string
  is_pin: boolean
  groups: string[]
  users: string[]
  tags: string[]
  created_at_ts?: number
  isSearchPage?: boolean
}
export const pinManager =
  ({
    post_id,
    is_pin,
    groups,
    users,
    tags,
    created_at_ts,
    isSearchPage,
  }: IPinManager): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { posts, searchPosts } = getState().feed
    try {
      const { data } = await httpAuth.put(endpoints.pinManager, {
        post_id,
        is_pin,
        groups,
        users,
        tags,
        created_at_ts,
      })
      const targetPosts = isSearchPage ? searchPosts : posts
      const updatedTargetPosts = targetPosts.map((post: Post) => {
        if (post.post_id === post_id) {
          return { ...post, pin_created_at_ts: data.pin_created_at_ts }
        }
        return post
      })

      if (isSearchPage) {
        dispatch(setSearchPosts(updatedTargetPosts))
      } else {
        dispatch(setPosts({ posts: updatedTargetPosts, isRefresh: true }))
      }
    } catch (error) {
      console.log(error)
    }
  }

export const updatePostViews =
  (postId: string, groups: string[], users: string[], tags: string[], isSearchPage = false) =>
  async (dispatch: any, getState: any) => {
    const {
      feed: { posts, searchPosts },
      config: {
        config: { myUID },
      },
    } = getState()

    const oldPosts = isSearchPage ? searchPosts : posts

    try {
      const url = endpoints.views(postId)

      const nextPosts = copy(oldPosts).map((post: any) => {
        if (post.post_id === postId) {
          ++post.views_counter
          post.viewed[myUID] = true
        }
        return post
      })
      await httpAuth.put(url, {
        post_id: postId,
        groups: groups,
        users: users,
        tags: tags,
      })
      if (isSearchPage) {
        dispatch(setSearchPosts(nextPosts))
      } else {
        dispatch(setPosts({ posts: nextPosts, isNewLike: true }))
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      return Promise.reject(error)
    }
  }

export const createGroup =
  ({
    title,
    content,
    groups,
    users,
    tags,
    images,
    type,
  }: {
    title: string
    content: string
    groups: string[]
    users: string[]
    tags: string[]
    images?: string[]
    type: number
  }): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      feed: { chats },
      comments: { commentsState },
    } = getState()

    dispatch(setFetching(true))
    try {
      const chatData = {
        title: title,
        type: type,
        groups,
        users,
        tags,
        images: images && images.length ? images : null,
      }

      const chatResponse = await httpAuth.post(endpoints.createPost, chatData)
      if (chatResponse.data) {
        const { post: chat } = chatResponse.data
        const commentData = {
          oid: chat.post_id,
          message: content,
          groups: chat.post_origin_groups,
          users: chat.post_origin_users,
          tags: chat.post_origin_tags,
          object_type:
            type === keys.POST_TYPE.GROUP_POST
              ? keys.COMMENT_OBJECT_TYPE.MESSAGE_ON_GROUP
              : keys.COMMENT_OBJECT_TYPE.MESSAGE_ON_ANNOUNCEMENT_GROUP,
          rg_id: chat.ras_id,
        }
        const commentResponse = await httpAuth.post(endpoints.comments, commentData)
        if (commentResponse.data) {
          const prevCommentsOfPost = commentsState?.[chat.post_id]?.items || []
          dispatch(setResponseStatus(true))
          dispatch(
            setChats({
              chats: addNewPost(chats, { ...chat, is_new: true }),
              isRefresh: true,
            })
          )
          dispatch(
            setComments({
              items: [...prevCommentsOfPost, commentResponse.data],
              key: chat.post_id,
              isReply: false,
            })
          )
          dispatch(setCommentsCounter({ count: prevCommentsOfPost.length + 1, key: chat.post_id }))
          dispatch(
            setViewedCommentsIds({
              key: chat.post_id,
              ids: [commentResponse.data.cid],
            })
          )
          dispatch(
            updateViews({
              postID: chat.post_id,
              viewedIds: [commentResponse.data.cid],
            })
          )
        }
      }
      return true
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      dispatch(setResponseStatus(false))
      return false
    } finally {
      dispatch(setFetching(false))
    }
  }

interface IHandleCreatePop {
  title: string
  message: string
  users: string[]
  groups: string[]
  popImage?: string
  isPopPost: boolean
}
export const createPop =
  ({
    title,
    message,
    users,
    groups,
    isPopPost,
    popImage,
  }: IHandleCreatePop): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      config: {
        config: { retailUsersObject, rid },
      },
      auth: { uid },
    } = getState()
    try {
      dispatch(setIsLoading(true))
      const body = {
        rid,
        data: {
          pop_title: title,
          pop_body: message,
          ack_btn_text: 'newsfeed_pop_ack_btn',
          profile_image_url: retailUsersObject?.[uid!]?.profile_img_url || null,
          pop_image_url: popImage || null,
          created_by: uid,
        },
        users: users,
        groups: groups,
        pop_type: keys.POP_TYPE.POP,
        is_pop_post: isPopPost,
      }

      await httpAuth.post(endpoints.pop, body)
      dispatch(setResponseStatus(true))
      updateLocalStorageItem('defaultAudience', { [uid!]: { groups: groups, users: users } })
      return true
    } catch (err) {
      dispatch(setResponseStatus(false))
      console.log(err)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }
