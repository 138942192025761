import { AxiosResponse } from 'axios'
import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { ITag, Result, Results, RootState, Subtasks, Task } from 'constants/interfaces'
import keys from 'constants/keys'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { extractedUidsFromGroups, extractSubstringAfterDelimiter, getUTCOffset } from 'utils'
import dayjs from 'dayjs'

export interface TaskCreationBody {
  groups?: string[]
  users?: string[]
  tags?: string[] | null
  from_ts: string
  to_ts: string
  deadline: string
  utc_offset: number
  send_schedule_push: boolean
  send_campaign_start_push: boolean
  data: PollData
  sub_tasks: (PollSubTask | Subtask)[]
  attached_images?: string
}

interface PollData {
  title: string
  is_recurring: boolean
  recurrence_frequency: null | number
  recurrence_days_of_week: null | string
  recurrence_interval: null | number
  desc: string
  categories: string[]
  view: number
  type: number
  is_individual_results: boolean
  recurrence_start_time: string | null
}
interface Subtask {
  category: string
  is_required: boolean
  title: string
  type: number
}

interface PollSubTask {
  title: string
  type: number
  is_required: boolean
  category: string
  is_multi_choice: boolean
  options: {
    [key: string]: string
  }
}

interface Response {
  data_columns: string[]
  data_rows: any[][]
}

interface RowObject {
  [key: string]: any
}

export interface TransformedDataWithCounts {
  [context_id: string]: {
    items: RowObject[]
    typeCounts: { [type: number]: number }
  }
}

export const actionTypes = {
  CREATE_TASK: 'CREATE_TASKS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_SUBTASKS: 'SET_SUBTASKS',
  SET_TASKS: 'SET_TASKS',
  SET_POLL_RESULTS: 'SET_POLL_RESULTS',
  SET_USER_RESULTS: 'SET_USER_RESULTS',
  SET_ALL_RESULTS: 'SET_ALL_RESULTS',
  SET_TASKS_INSIGHTS: 'SET_TASKS_INSIGHTS',
}

export const setIsLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_LOADING,
  payload: isLoading,
})

export const setSubtasks = (subtasks: Subtasks) => ({
  type: actionTypes.SET_SUBTASKS,
  payload: subtasks,
})

export const setTasks = (tasks: Task[]) => ({
  type: actionTypes.SET_TASKS,
  payload: tasks,
})

export const setPollResults = (pollResults: Results) => ({
  type: actionTypes.SET_POLL_RESULTS,
  payload: pollResults,
})

export const setUserResults = (userResults: Results) => ({
  type: actionTypes.SET_USER_RESULTS,
  payload: userResults,
})

export const setAllResults = (allResults: Results) => ({
  type: actionTypes.SET_ALL_RESULTS,
  payload: allResults,
})
export const setOtherUsersResults = (otherResults: Results) => ({
  type: actionTypes.SET_USER_RESULTS,
  payload: otherResults,
})

export const setTasksInsights = (insights: TransformedDataWithCounts) => ({
  type: actionTypes.SET_TASKS_INSIGHTS,
  payload: insights,
})

export const createTask =
  (data: TaskCreationBody): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const response = await httpAuth.post(endpoints.tasksV2, data)
      return !!response
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const getTasks =
  (): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    const { activeGroupID } = getState().config
    const tags: string[] = []
    try {
      const response = await httpAuth.get(endpoints.tasksV2, {
        params: {
          context_id: activeGroupID,
          utc_offset: getUTCOffset(),
          ...(tags.length > 0 ? { tags } : {}),
        },
      })
      const { data, status } = response
      dispatch(setSubtasks(data.sub_tasks))
      dispatch(setTasks(data.tasks))
      dispatch(setUserResults(data.results))
      return status === 200
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }

interface UpdatePollResultsBody {
  tid: string
  st_id: string
  status: number
  type: number
  votes?: number[]
  images?: string[]
  videos?: string[]
  audios?: string[]
  files?: string[]
  specificContextId: string | null
}
const updateResultsArray = (userResults: Result[], updatedResult: Result): Result[] => {
  const index = userResults.findIndex(
    (result) => result.st_id === updatedResult.st_id && result.context_id === updatedResult.context_id
  )

  if (index !== -1) {
    const newArray = [...userResults]
    newArray[index] = updatedResult
    return newArray
  }

  return [...userResults, updatedResult]
}

const buildSubtaskDataObject = (
  type: number,
  images?: string[],
  videos?: string[],
  files?: string[],
  votes?: number[]
) => {
  switch (type) {
    case keys.SUBTASK_TYPES.IMAGE_SUBTASK:
      return { images: images || [] }
    case keys.SUBTASK_TYPES.VIDEO_SUBTASKS:
      return { videos: videos || [] }
    case keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK:
      return { files: files || [] }
    case keys.SUBTASK_TYPES.POLL_SUBTASK:
      return { poll_selections: votes || [] }
    default:
      return {}
  }
}
type calculateContextIDArgs = {
  recipient_type: number
  is_individual_results: boolean
  uid: string
  groupId: string
  tagId: string | null
  tags: ITag[]
  retail_id_context_id: string
  rid: string
}

type calculateContextID = {
  currentTag: ITag
  uid: string
  groupId: string
}
const calculateTagContextId = ({ currentTag, uid, groupId }: calculateContextID) => {
  const tagGroups = currentTag?.groups ?? []
  const tagUsers = currentTag?.users ?? []

  if (tagUsers.includes(uid)) {
    return uid
  }
  if (tagGroups.includes(groupId)) {
    return groupId
  }
  return uid
}
export const calculateContextID = ({
  recipient_type,
  is_individual_results,
  uid,
  groupId,
  retail_id_context_id,
  rid,
  tagId,
  tags,
}: calculateContextIDArgs) => {
  if (recipient_type === 0 || is_individual_results) {
    return uid
  }
  if (recipient_type === 2) {
    const currentTag = tags.find((tag) => tag.sk === tagId)
    if (!currentTag) {
      return uid
    }
    return calculateTagContextId({ currentTag, uid, groupId })
  }
  return extractSubstringAfterDelimiter(retail_id_context_id, rid)
}

export const updateResults =
  ({
    tid,
    votes,
    status,
    images,
    videos,
    files,
    st_id,
    type,
    specificContextId,
  }: UpdatePollResultsBody): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks } = getState().tasks_v2
    const { uid } = getState().auth
    const { tags } = getState().config.config
    const {
      activeGroupID,
      config: { rid },
    } = getState().config
    const { userResults, allResults } = getState().tasks_v2
    const currentTask = tasks.find((task) => task.tid === tid)
    const currentSubtask = getState().tasks_v2.subtasks[tid].find((subtask) => subtask.st_id === st_id)

    const contextId =
      specificContextId ||
      calculateContextID({
        recipient_type: currentTask?.recipient_type!,
        is_individual_results: currentTask?.is_individual_results!,
        uid: uid!,
        groupId: activeGroupID!,
        tagId:
          currentTask?.recipient_type === 2
            ? extractSubstringAfterDelimiter(currentTask?.retail_id_context_id!, rid)
            : null,
        retail_id_context_id: currentTask?.retail_id_context_id!,
        rid,
        tags,
      })

    const userResultOfCurrentSubtask = userResults?.[tid]?.find(
      (result) => result.st_id === st_id && result.context_id === contextId
    )

    const isFirstResult = !userResultOfCurrentSubtask

    const contextIdRecurrenceTs = `${contextId}_${currentTask?.recurrence_ts}`
    const updatedCurrentResult = {
      sub_task_type: type,
      st_id,
      status: status,
      context_id: contextId,
      context_id_recurrence_ts: isFirstResult
        ? contextIdRecurrenceTs
        : userResultOfCurrentSubtask.context_id_recurrence_ts,
      ...(type === keys.SUBTASK_TYPES.POLL_SUBTASK
        ? { is_multi_choice: currentSubtask?.is_multi_choice ?? false }
        : {}),
      updated_by: uid,
      updated_at_ts: Math.floor(Date.now() / 1000),
      ...buildSubtaskDataObject(type, images, videos, files, votes),
    }

    const updatedUserResults: Results = {
      ...userResults,
      [tid]: updateResultsArray(userResults?.[tid] || [], updatedCurrentResult),
    }

    const updatedAllResults: Results = {
      ...allResults,
      [tid]: updateResultsArray(allResults?.[tid] || [], updatedCurrentResult),
    }

    dispatch(setUserResults(updatedUserResults))
    dispatch(setAllResults(updatedAllResults))

    const data = {
      st_id,
      tid,
      task_context_id: extractSubstringAfterDelimiter(currentTask?.retail_id_context_id!, rid),
      context_id: specificContextId || activeGroupID,
      is_individual_results: currentTask?.is_individual_results,
      recurrence_ts: isFirstResult
        ? currentTask?.recurrence_ts || dayjs().utc().format('YYYYMMDDHHmmss')
        : extractSubstringAfterDelimiter(userResultOfCurrentSubtask?.context_id_recurrence_ts!, contextId),
      recurrence_start_time: currentTask?.is_recurring ? currentTask?.recurrence_start_time : null,
      utc_offset: getUTCOffset(),
      is_first_result: isFirstResult,
      recipient_type: currentTask?.recipient_type,
      data: {
        sub_task_type: type,
        status: status,
        ...(currentSubtask?.type === keys.SUBTASK_V2_TYPES.POLL_SUBTASK
          ? { is_multi_choice: currentSubtask?.is_multi_choice ?? false }
          : {}),
        ...buildSubtaskDataObject(type, images, videos, files, votes),
      },
    }

    try {
      const response = await httpAuth.post(endpoints.updateResults, data)
      return !!response
    } catch (e) {
      console.log(e)
      return false
    }
  }

export const deleteTask =
  ({ tid }: { tid: string }): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(setIsLoading(true))

    const tasks = getState().tasks_v2.tasks
    const updatedTasks = tasks.filter((task) => task.tid !== tid)
    dispatch(setTasks(updatedTasks))

    const isDeleteAll = true
    const config = {
      data: {
        tid,
        is_delete_all: isDeleteAll,
      },
    }

    try {
      const response = await httpAuth.delete(endpoints.tasksV2, config)
      return !!response
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const getAllResults =
  ({ tid }: { tid: string }): ThunkAction<Promise<Results | null>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks_v2, config } = getState()
    const { tasks, subtasks } = tasks_v2
    const { activeGroupID } = config

    try {
      dispatch(setIsLoading(true))
      const currentTask = tasks.find((task) => task.tid === tid)
      const currentSubtasks = subtasks[tid]

      if (!currentTask) {
        return null
      }

      const body = {
        task: currentTask,
        sub_tasks: currentSubtasks,
      }

      const response: AxiosResponse<any> = await httpAuth.post(endpoints.getAllResults(activeGroupID!), body)
      const { data } = response

      const allResultsOfCurrentTask: Results = {
        [tid]: data,
      }
      dispatch(setAllResults(allResultsOfCurrentTask))

      return allResultsOfCurrentTask
    } catch (e) {
      console.error(e)
      return null
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const getTaskResultSpecificContext =
  ({ tid, contextId }: { tid: string; contextId: string }): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks_v2 } = getState()
    const { userResults, allResults } = tasks_v2
    dispatch(setIsLoading(true))
    try {
      const currentTask = tasks_v2.tasks.find((task) => task.tid === tid)

      const body = {
        task: currentTask,
        sub_tasks: tasks_v2.subtasks?.[tid] ?? [],
      }

      const response = await httpAuth.post(endpoints.getTaskSpecificContext(contextId), body)
      const { data } = response

      const results: Result[] = data?.[tid] ? data[tid] : []

      const existingUserResults =
        userResults[tid]?.filter(
          (result) => !results.some((r) => r.st_id === result.st_id && r.context_id === result.context_id)
        ) || []
      const userResultsOfCurrentTask: Results = {
        ...userResults,
        [tid]: [...existingUserResults, ...results],
      }

      const existingAllResults =
        allResults[tid]?.filter(
          (result) => !results.some((r) => r.st_id === result.st_id && r.context_id === result.context_id)
        ) || []
      const allResultsOfCurrentTask: Results = {
        ...allResults,
        [tid]: [...existingAllResults, ...results],
      }

      dispatch(setAllResults(allResultsOfCurrentTask))
      dispatch(setUserResults(userResultsOfCurrentTask))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

const transformResponseWithCounts = (response: Response): TransformedDataWithCounts => {
  try {
    const { data_columns, data_rows } = response
    if (!data_columns || !data_rows) {
      return {}
    }

    // Transform the response data into a structure with counts per type
    return data_rows.reduce<TransformedDataWithCounts>((acc, row) => {
      const rowObject = data_columns.reduce<RowObject>((obj, column, index) => {
        obj[column] = row[index]
        return obj
      }, {})

      const contextId: string = rowObject.context_id
      const type: number = rowObject.type

      if (!acc[contextId]) {
        acc[contextId] = { items: [], typeCounts: {} }
      }

      // Push the row object into the items array
      acc[contextId].items.push(rowObject)

      // Count the occurrences of each type
      if (!acc[contextId].typeCounts[type]) {
        acc[contextId].typeCounts[type] = 0
      }
      acc[contextId].typeCounts[type] += 1

      return acc
    }, {})
  } catch (e) {
    console.error(e)
    return {}
  }
}

export const getTasksInsights =
  ({ tid, st_id }: { tid: string; st_id: string }): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { tasks } = getState().tasks_v2
    const {
      config: { groups: groupsObject, tags: retailTags },
    } = getState().config
    const { groups: permittedGroups, users: permittedUsers } = getState().config.userWriteTaskPermissions

    dispatch(setIsLoading(true))
    try {
      const currentTask = tasks.find((task) => task.tid === tid)
      if (!currentTask) return

      const {
        groups,
        users,
        tags,
        is_recurring,
        is_individual_results,
        recurrence_frequency,
        recurrence_interval,
        from_ts,
      } = currentTask

      const filteredRetailTags = retailTags.filter((tag) => tags.includes(tag.sk))
      const tagsGroups = filteredRetailTags.map((tag) => tag?.groups ?? []).flat()
      const tagsUsers = filteredRetailTags.map((tag) => tag?.users ?? []).flat()

      const mergedGroups = Array.from(new Set([...groups, ...tagsGroups]))
      const mergedUsers = Array.from(new Set([...users, ...tagsUsers]))

      const groupsToPayload = is_individual_results ? [] : mergedGroups
      const usersToPayload = is_individual_results
        ? Array.from(new Set([...extractedUidsFromGroups(mergedGroups, groupsObject), ...mergedUsers]))
        : mergedUsers

      const body = {
        tid,
        st_id_to_exclude: st_id,
        groups: groupsToPayload.filter((group) => Object.keys(permittedGroups).includes(group)) ?? [],
        users: usersToPayload.filter((user) => permittedUsers.includes(user)) ?? [],
        ...(is_recurring
          ? {
              is_recurring,
              recurrence_frequency,
              recurrence_interval,
              from_ts,
            }
          : {}),
      }

      const response = await httpAuth.post(endpoints.getTasksInsights, body)
      const transformedData = transformResponseWithCounts(response.data)

      dispatch(setTasksInsights(transformedData))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setIsLoading(false))
    }
  }
