import React, { useState, useEffect, useMemo } from 'react'
import { Button, Divider, Box, Flex, Avatar } from '@chakra-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import isSolid from 'is-solid'
import useAvatar from 'hooks/useAvatar'
import colors from 'constants/colors'
import { IConfigRetailUser, ILike, RootState } from 'constants/interfaces'
import BaseModal from 'components/CommonComponents/BaseModal'
import { pingUser } from 'redux/actions/feed'
import { Post } from 'redux/reducers/feed'
import useLocaleText from 'components/useLocaleText'
import { PingedButton } from './PingedButton'
import { ReactComponent as LikeIconActive } from '../../assets/like-active.svg'
import { useSearchPage } from 'hooks/useSearchPage'

interface IPropsLikesModal {
  isOpen: boolean
  onClose: () => void
  likes_counter: number
  liked: ILike
  post_id?: string
  created_by?: string
  post?: Post
  isShowPings?: boolean
}

interface IPropsListItem {
  firstName?: string
  lastName?: string
  uid?: string
  isLiked?: boolean
  post?: Post
}

const ListItem = ({ firstName, lastName, uid, isLiked, post }: IPropsListItem) => {
  const dispatch = useDispatch()
  const myUID = useSelector((state: RootState) => state.auth.uid)
  const theme = useSelector((state: RootState) => state.general.theme)
  const avatar = useAvatar(uid)
  const { isSearchPage } = useSearchPage()
  const pingLiteral = useLocaleText('ping')
  const isPinged = !!uid && post?.ping?.[uid].status
  const pingUserHandler = () => {
    if (uid && post) {
      dispatch(
        pingUser({
          post_id: post.post_id,
          groups: post.groups,
          users: post.users,
          tags: post.tags ?? [],
          users_to_ping: { [uid]: { context_id: post.ping[uid].context_id, status: true } },
          isSearchPage,
        })
      )
    }
  }

  const isShowPingButton = useMemo(() => {
    return post && post?.created_by === myUID && Object.keys(post.ping).includes(uid!) && !isLiked
  }, [isLiked, myUID, post, uid])

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Box position="relative">
            <Avatar src={avatar} size="sm" style={{ width: '40px', height: '40px' }} />
          </Box>

          <Box fontWeight="600" mx="20px">
            {firstName} {lastName}
          </Box>
        </Flex>
        {isShowPingButton && (
          <Box>
            {!isPinged ? (
              <Button
                variantColor="white"
                fontWeight="500"
                borderRadius="16px"
                background={theme?.elementsColor}
                height="32px"
                pl="24px"
                pr="24px"
                onClick={pingUserHandler}
                _focus={{
                  outline: 'none',
                }}
              >
                {pingLiteral}
              </Button>
            ) : (
              <PingedButton />
            )}
          </Box>
        )}
        {isLiked && (
          <LikeIconActive
            height="34px"
            width="34px"
            style={{ marginTop: '10px', '--svg-color': theme?.elementsColor } as React.CSSProperties}
          />
        )}
      </Flex>
      <Divider />
    </>
  )
}

const LikesModal = ({
  isOpen,
  onClose,
  likes_counter,
  liked,
  post_id,
  post,
  created_by,
  isShowPings = true,
}: IPropsLikesModal) => {
  const dispatch = useDispatch()
  const theme = useSelector((state: RootState) => state.general.theme)
  const [sortedRetailUsers, setRetailUsers] = useState<IConfigRetailUser[]>([])
  const retailUsers = useSelector((state: RootState) => state.config.config.retail_users)
  const userId = useSelector((state: RootState) => state.auth.uid)
  const pingAllLiteral = useLocaleText('ping_all')
  const pingedAllLiteral = useLocaleText('pinged_all')
  const modalHeaderLiteral = useLocaleText('post_like_list_title')
  const sentToLiteral = useLocaleText('sent_to')

  const countOfPingedUsers = post?.post_id
    ? sortedRetailUsers.reduce((count, user) => {
        if (user.uid) {
          if (post.liked[user.uid] || post.ping[user.uid].status) {
            return (count += 1)
          }
        }
        return count
      }, 0)
    : 0
  const isAuthorOfPost = useMemo(() => {
    return post?.created_by === userId
  }, [post, userId])

  const retailUsersWhoCanPing = useMemo(() => {
    if (!post?.ping) return []
    return sortedRetailUsers?.filter((retUser) => Object.keys(post.ping).includes(retUser.uid)) || []
  }, [post, sortedRetailUsers])

  const isAllUserPinged = useMemo(() => {
    return countOfPingedUsers === retailUsersWhoCanPing.length
  }, [countOfPingedUsers, retailUsersWhoCanPing.length])

  useEffect(() => {
    const currentUsers = Object.keys(liked)
      .map((item: string) => {
        const findUser = retailUsers && retailUsers.find((retUser: IConfigRetailUser): boolean => retUser.uid === item)
        const user = {
          isLiked: liked[item],
          ...findUser,
        }
        return user
      })
      .filter((user) => (post ? user.uid !== post?.created_by : user.uid !== created_by))
      .sort((a, b) => +a.isLiked - +b.isLiked)
    //@ts-ignore
    setRetailUsers(currentUsers)
  }, [retailUsers, liked, post, created_by])

  const pingAllUsersHandler = () => {
    let pings = {}
    if (post && post?.ping && post_id && !isAllUserPinged) {
      Object.keys(post?.ping).forEach((userId) => {
        if (!post.ping[userId].status && post.created_by !== userId && !post.liked[userId]) {
          let currentPinged = { [userId]: { context_id: post?.ping[userId].context_id, status: true } }
          pings = {
            ...pings,
            ...currentPinged,
          }
        }
      })
      dispatch(
        pingUser({
          post_id,
          groups: post.groups,
          users: post.users,
          tags: post.tags ?? [],
          users_to_ping: pings,
          isSearchPage: false,
        })
      )
    }
  }

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        headerContent={<>{modalHeaderLiteral}</>}
        bodyContent={
          <Box px="6">
            <Flex justifyContent="space-between" alignItems="center" color={colors.greyMain}>
              <Flex alignItems="center">
                <LikeIconActive
                  height="34px"
                  width="34px"
                  style={{ marginTop: '10px', '--svg-color': theme?.elementsColor } as React.CSSProperties}
                />
                <Box>{likes_counter}</Box>
              </Flex>
              <Flex alignItems="center">
                <Box mx="16px">
                  {sentToLiteral}: {sortedRetailUsers.length}
                </Box>
                {isAuthorOfPost && isShowPings && (
                  <Button
                    variantColor="white"
                    background={theme?.elementsColor}
                    fontWeight="500"
                    borderRadius="16px"
                    height="32px"
                    pl="24px"
                    pr="24px"
                    onClick={pingAllUsersHandler}
                    isDisabled={isAllUserPinged}
                    variant="solid"
                    _focus={{ boxShadow: 'none' }}
                  >
                    {isAllUserPinged ? pingedAllLiteral : pingAllLiteral}
                  </Button>
                )}
              </Flex>
            </Flex>
            <Divider />
            {isSolid(sortedRetailUsers) &&
              sortedRetailUsers.map((user: IConfigRetailUser) => (
                <ListItem
                  key={user.uid}
                  firstName={user.first_name}
                  lastName={user.last_name}
                  uid={user.uid}
                  isLiked={user.isLiked}
                  post={post}
                />
              ))}
          </Box>
        }
      />
    </>
  )
}

export default LikesModal
