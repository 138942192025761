import { Box, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import React, { memo, useState } from 'react'
import { AudienceOptionItem } from './AudienceOptionItem'
import { IAudienceOptionsList } from './types'
import useLocaleText from 'components/useLocaleText'
import { OptionType } from 'components/NewsFeed/constants'

export const AudienceOptionsList = memo(
  ({
    renderViewOptionModal,
    selectedUsersUids,
    groupsToPayload,
    groups,
    users,
    selectedTagsIds,
    selectedOptionType,
  }: IAudienceOptionsList) => {
    const [optionValue, setOptionValue] = useState('0')
    const [viewOption, setViewOption] = useState<number | null>(null)
    const post_audience_select_users = useLocaleText('post_audience_select_users')
    const post_audience_select_groups = useLocaleText('post_audience_select_groups')
    const post_audience_select_tags = useLocaleText('post_audience_select_tags')
    const AUDIENCE_OPTIONS = [
      {
        title: post_audience_select_groups,
        icon: 'https://sales-media.apps-checkout.com/default/icons/posts/groups_black_24dp.svg',
      },
      {
        title: post_audience_select_users,
        icon: 'https://sales-media.apps-checkout.com/default/icons/posts/users_black_24dp.svg',
      },
      ...(selectedOptionType && selectedOptionType !== OptionType.POP
        ? [
            {
              title: post_audience_select_tags,
              icon: 'https://sales-media.checkout-apps.com/default/icons/tag.svg',
            },
          ]
        : []),
    ]

    return (
      <>
        {viewOption === null && (
          <Box px="5%">
            <Text fontSize="18px" pt="15px" style={{ color: 'gray' }}>
              <LocaleText text="post_audience_title" />
            </Text>
            <Text fontSize="18px" style={{ color: 'gray' }}>
              <LocaleText text="post_audience_description" />
            </Text>
            <Text fontSize="18px" pb="15px" style={{ color: 'gray' }}>
              <LocaleText text="post_audience_choose_audience" />
            </Text>

            {AUDIENCE_OPTIONS.map((option, index) => {
              return (
                <AudienceOptionItem
                  key={index}
                  title={option.title}
                  icon={option.icon}
                  index={index}
                  setOptionValue={setOptionValue}
                  optionValue={optionValue}
                  setViewOption={setViewOption}
                  groupsToPayload={groupsToPayload}
                  selectedUsersUids={selectedUsersUids}
                  selectedTagsIds={selectedTagsIds}
                  groups={groups}
                  users={users}
                />
              )
            })}
          </Box>
        )}
        {viewOption !== null && <>{renderViewOptionModal(viewOption, setViewOption)}</>}
      </>
    )
  }
)
