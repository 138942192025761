import {
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React, { memo, useRef } from 'react'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { SubTask } from './utils'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import strings from 'constants/strings'
import { useMediaState } from 'hooks/useMediaState'
import { regExLinkRecognizer } from 'constants/baseValues'

interface IProps {
  label: string
  id: number
  placeholder: string
  subTasks: SubTask[]
  index: number
  errors: { [key: string]: boolean }
  onAddSubtask: (id: number) => void
  onRemoveSubtask: (fieldId: number, index: number) => void
  onChangeSubtask: (fieldId: number, index: number, value: string) => void
  onChangeSubtaskTitle: (fieldId: number, index: number, value: string) => void
  handleBlur: () => void
}
export const SubtaskField = memo(
  ({
    label,
    subTasks,
    id,
    index,
    placeholder,
    onAddSubtask,
    onRemoveSubtask,
    onChangeSubtask,
    onChangeSubtaskTitle,
    handleBlur,
    errors,
  }: IProps) => {
    const linkSubtaskFileInputRef = useRef<HTMLInputElement>(null)

    const { handleFileUpload, isUploadLoading } = useMediaState({
      files: [],
    })

    const { isLoading } = useSelector((state: RootState) => state.tasks_v2)
    const t_incorrent_link = useLocaleText('t_incorrent_link')
    const t_empty_field = useLocaleText('t_empty_field')
    const inputPlaceholder = useLocaleText(placeholder)

    const handleClickOnAddFile = () => {
      linkSubtaskFileInputRef.current?.click()
    }

    const handleLinkSubtaskFiles = async (
      e: React.ChangeEvent<HTMLInputElement>,
      subtaskIndex: number,
      title?: string
    ) => {
      const response = await handleFileUpload(e)
      const fileName = response?.fileName
      const fileUrl = response?.fileUrl

      if (fileUrl && fileName) {
        onChangeSubtaskTitle(id, subtaskIndex, fileUrl)
        onChangeSubtask(id, subtaskIndex, title ? title : fileName)
      }
    }

    return (
      <Stack direction="column">
        <Button
          onClick={() => onAddSubtask(id)}
          flexDir="row"
          mb="0px"
          cursor="pointer"
          w="max-content"
          bg="white"
          borderRadius="10px"
          border="none"
          mt={index === 0 ? '20px' : '10px'}
          px="10px"
          _focus={{ boxShadow: 'none' }}
        >
          <Flex alignItems="center" fontSize="16px" fontWeight="bold">
            <Icon name="add" />
            <Text mx="5px" w="max-content">
              <LocaleText text={label} />
            </Text>
          </Flex>
        </Button>

        {subTasks.map((subTask, index) => {
          return (
            <Flex flexDir="column" key={index} w="100%" h="100%" my="10px">
              <Flex>
                <Input
                  key={index}
                  isDisabled={isLoading || isUploadLoading}
                  placeholder={inputPlaceholder}
                  type="text"
                  borderRadius="10px"
                  px="10px"
                  value={subTask.title}
                  onChange={(e) => onChangeSubtask(id, index, (e.target as HTMLInputElement).value)}
                  onBlur={handleBlur}
                  borderColor={!subTask.title ? 'red.500' : 'gray.200'}
                />
                <IconButton
                  borderRadius="full"
                  aria-label="Remove option"
                  icon={IoClose}
                  mx="10px"
                  isDisabled={isLoading || isUploadLoading}
                  onClick={() => onRemoveSubtask(id, index)}
                />
              </Flex>
              {!subTask.title && (
                <Text color="red.500" fontSize="12px" px="10px" pt="5px">
                  {t_empty_field}
                </Text>
              )}
              {'external_link' in subTask && (
                <>
                  <InputGroup>
                    <InputLeftElement cursor="pointer">
                      <IconButton
                        icon="attachment"
                        aria-label={'upload file'}
                        minW="unset"
                        size="lg"
                        bg="transparent"
                        borderRadius="full"
                        transition="ease-in-out 0.2s"
                        isDisabled={isLoading || isUploadLoading}
                        _hover={{ bg: 'transparent', transform: 'scale(1.2)' }}
                        _focus={{ boxShadow: 'none' }}
                        _active={{ bg: 'transparent' }}
                        onClick={handleClickOnAddFile}
                      />
                      <Input
                        type="file"
                        ref={linkSubtaskFileInputRef}
                        // @ts-ignore
                        onChange={(e) => handleLinkSubtaskFiles(e, index, subTask.title)}
                        accept={strings.POST_FILE_ACCEPT_EXTENSIONS}
                        style={{ display: 'none' }}
                      />
                    </InputLeftElement>
                    <Input
                      mb="10px"
                      isDisabled={isLoading || isUploadLoading}
                      placeholder={subTask.placeholder}
                      type="text"
                      px="10px"
                      marginBottom="0px"
                      color={regExLinkRecognizer.test(subTask.external_link!) ? 'blue.500' : 'red.500'}
                      value={subTask.external_link}
                      onChange={(e) => onChangeSubtaskTitle(id, index, (e.target as HTMLInputElement).value)}
                      onBlur={handleBlur}
                      border="none"
                      borderBottom="1px solid"
                      borderBottomColor={errors[`${id}-${index}`] ? 'red.500' : 'gray.200'}
                      borderRadius="0"
                      _focus={{ boxShadow: 'none' }}
                    />
                  </InputGroup>
                  <>
                    {errors[`${id}-${index}`] && (
                      <Text color="red.500" fontSize="12px" px="10px" pt="5px">
                        {t_incorrent_link}
                      </Text>
                    )}
                  </>
                </>
              )}
            </Flex>
          )
        })}
        <Divider />
      </Stack>
    )
  }
)
