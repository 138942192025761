import { RootState, IReportFetchResponse, ITag, IDataFilterValue, IConfigStateGroups } from 'constants/interfaces'
import endpoints from 'constants/endpoints'
import { httpAuth } from 'config/apiClient'
import dayjs from 'dayjs'
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux'
import { isEmpty, isNumber } from 'lodash'
import { IDeepDive } from 'redux/reducers/reports'
import avatarPlaceholder from 'assets/avatar-placeholder.svg'
import { AxiosError } from 'axios'

export const actionTypes = {
  SET_REPORTS: '[REPORTS] SET_REPORTS',
  SET_IS_REPORT_LOADING: '[REPORTS] SET_IS_REPORT_LOADING',
  SET_REPORT_RANGE_TYPE: '[REPORTS] SET_REPORT_RANGE_TYPE',
  SET_REPORT_FILTER_OPTIONS: '[REPORTS] SET_REPORT_FILTER_OPTIONS',
  SET_SELECTED_TAG: '[REPORTS] SET_SELECTED_TAG',
  SET_DATA_FILTER: '[REPORTS] SET_DATA_FILTER',
  SET_DEEP_DIVE_DATA_FILTER: '[REPORTS] SET_DEEP_DIVE_DATA_FILTER',
  SET_STORE_DATA_FILTER: '[REPORTS] SET_STORE_DATA_FILTER',
  SET_IS_REPORT_ERROR: '[REPORTS] SET_IS_REPORT_ERROR',
  SET_SELECTED_USERS_IDS: '[REPORTS] SET_UID',
  SET_PERMITTED_USERS: '[REPORTS] SET_PERMITTED_USERS',
  RESET_REPORTS: '[REPORTS] RESET_REPORTS',
  SET_DEEP_DIVE_CONFIG: '[REPORTS] SET_DEEP_DIVE_CONFIG',
  SET_REPORTS_TAGS_LIST: '[REPORTS] SET_REPORTS_TAGS_LIST',
  SET_DEEP_DIVE_REPORT_RANGE_TYPE: '[REPORTS] SET_DEEP_DIVE_REPORT_RANGE_TYPE',
  SET_DEEP_DIVE_SELECTED_USERS_IDS: '[REPORTS] SET_DEEP_DIVE_SELECTED_USERS_IDS',
  SET_DEEP_DIVE_SELECTED_TAG: '[REPORTS] SET_DEEP_DIVE_SELECTED_TAG',
  SET_STORE_REPORT_RANGE_TYPE: '[REPORTS] SET_STORE_REPORT_RANGE_TYPE',
  SET_STORE_SELECTED_TAG: '[REPORTS] SET_STORE_SELECTED_TAG',
  SET_STORE_SELECTED_USERS_IDS: '[REPORTS] SET_STORE_SELECTED_USERS_IDS',
}
export const resetReports = () => ({
  type: actionTypes.RESET_REPORTS,
})
export const setPermittedUsers = (users: PermitedUsersList) => ({
  type: actionTypes.SET_PERMITTED_USERS,
  payload: users,
})
export const setSelectedUsers = (uids: string[]) => ({
  type: actionTypes.SET_SELECTED_USERS_IDS,
  payload: uids,
})
export const setDeepDiveSelectetUsers = (uids: string[]) => ({
  type: actionTypes.SET_DEEP_DIVE_SELECTED_USERS_IDS,
  payload: uids,
})

export const setStoreSelectedUsersIds = (uids: string[]) => ({
  type: actionTypes.SET_STORE_SELECTED_USERS_IDS,
  payload: uids,
})
export const setDataFilter = (dataFilters: IDataFilterValue) => ({
  type: actionTypes.SET_DATA_FILTER,
  payload: dataFilters,
})
export const setDeepDiveDataFilter = (dataFilters: IDataFilterValue) => ({
  type: actionTypes.SET_DEEP_DIVE_DATA_FILTER,
  payload: dataFilters,
})

export const setStoreDataFilter = (dataFilters: IDataFilterValue) => ({
  type: actionTypes.SET_STORE_DATA_FILTER,
  payload: dataFilters,
})
export const setSelectedTag = (tag: ITag | null) => ({
  type: actionTypes.SET_SELECTED_TAG,
  payload: tag,
})
export const setDeepDiveSelectedTag = (tag: ITag | null) => ({
  type: actionTypes.SET_DEEP_DIVE_SELECTED_TAG,
  payload: tag,
})
export const setStoreSelectedTag = (tag: ITag | null) => ({
  type: actionTypes.SET_STORE_SELECTED_TAG,
  payload: tag,
})
export const setReportFilterOptions = (tags: ITag[]) => ({
  type: actionTypes.SET_REPORT_FILTER_OPTIONS,
  payload: tags,
})
export const setReportRangeType = (type: number, title: string) => ({
  type: actionTypes.SET_REPORT_RANGE_TYPE,
  payload: { type, title },
})

export const setDeepDiveReportRangeType = (type: number, title: string) => ({
  type: actionTypes.SET_DEEP_DIVE_REPORT_RANGE_TYPE,
  payload: { type, title },
})
export const setStoreReportRangeType = (type: number, title: string) => ({
  type: actionTypes.SET_STORE_REPORT_RANGE_TYPE,
  payload: { type, title },
})
export const setIsReportLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_REPORT_LOADING,
  payload: isLoading,
})

export const setIsReportError = (isError: boolean, statusCode?: number) => ({
  type: actionTypes.SET_IS_REPORT_ERROR,
  payload: { isError, statusCode },
})
export const setReportsData = (reports: IReportFetchResponse[], key: string) => ({
  type: actionTypes.SET_REPORTS,
  payload: { reports, key },
})
export const setDeepDiveConfig = (deepDiveConfig: IDeepDive) => ({
  type: actionTypes.SET_DEEP_DIVE_CONFIG,
  payload: deepDiveConfig,
})

export const setReportsTagsList = (tags: ITag[]) => ({
  type: actionTypes.SET_REPORTS_TAGS_LIST,
  payload: tags,
})

interface IFetchReports {
  groupId: string
  startDate?: Date
  endDate?: Date
  report_range_type?: number
  tag?: string
  sub_retail_id: string | undefined
  dataFilter?: IDataFilterValue
  users?: string[]
  reportDataKey: string
  componentId?: number | null
  subComponentId?: string | null
  componentFilter?: string | null
  is_deep_dive?: boolean
}
export type PermitedUsersList = {
  id: string
  name: string
  image: string
  groups: any
}[]

function convertGroupsToGroupData(groups: IConfigStateGroups) {
  const groupData = {}

  for (const groupId in groups) {
    const group = groups[groupId]
    const { name, uids } = group

    for (const uid of uids) {
      if (!groupData[uid]) {
        groupData[uid] = []
      }
      groupData[uid].push(name)
    }
  }

  return groupData
}

export const getReportsPermittedUsers =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    const {
      activeGroupID,
      config: { groups, retailUsersObject },
    } = getState().config
    if (!activeGroupID) return
    try {
      const url = endpoints.readReportsPermissions(activeGroupID)
      const { data } = await httpAuth.get(url)

      if (data.length && retailUsersObject) {
        const permittedUsersIds: string[] = data

        const usersGroups = convertGroupsToGroupData(groups)
        const pemittedUsersList = permittedUsersIds?.map((uid) => {
          const user = retailUsersObject[uid]
          return {
            id: user.uid,
            name: `${user.first_name} ${user.last_name ? user.last_name : ''}`,
            image: user.profile_img_url || avatarPlaceholder,
            groups: usersGroups[user.uid],
          }
        })
        dispatch(setPermittedUsers(pemittedUsersList))
      } else {
        dispatch(setPermittedUsers([]))
      }
    } catch (error) {
      console.log(error)
    }
  }

export const fetchAndSetReportData =
  ({
    groupId,
    startDate,
    endDate,
    tag,
    users,
    report_range_type,
    sub_retail_id,
    dataFilter,
    reportDataKey,
    componentId,
    subComponentId,
    componentFilter,
    is_deep_dive,
  }: IFetchReports): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(setIsReportLoading(true))
    dispatch(setIsReportError(false))
    try {
      const {
        config: { retailConfig },
        reports: { selectedDataFilter },
      } = getState()

      if (!selectedDataFilter && dataFilter) {
        dispatch(setDataFilter(dataFilter))
      }
      const subRetailId = sub_retail_id ? sub_retail_id : retailConfig?.sub_retail_id

      const formattedStartDate = startDate ? dayjs(startDate).format('YYYYMMDD') : ''
      const formattedEndDate = endDate ? dayjs(endDate).format('YYYYMMDD') : ''

      const params = {
        group: groupId,
        report_range_type,
        ...(tag ? { tag: tag } : {}),
        ...(dataFilter ? { data_filter: dataFilter.value } : {}),
        ...(subComponentId ? { sub_component_id: subComponentId } : {}),
        ...(componentFilter ? { component_filter: componentFilter } : {}),
        ...(isNumber(componentId) ? { component_id: componentId } : {}),
        ...(subRetailId ? { sub_retail_id: subRetailId } : {}),
        ...(formattedStartDate ? { from_ts: formattedStartDate } : {}),
        ...(formattedEndDate ? { to_ts: formattedEndDate } : {}),
        ...(is_deep_dive ? { is_deep_dive } : {}),
        ...(users?.length ? { users: users.join(',') } : {}),
      }

      const url = endpoints.getReport
      const { data } = await httpAuth.get(url, { params })

      if (!isEmpty(data)) {
        const { reports } = data
        dispatch(setReportsData(reports, reportDataKey))
      } else {
        dispatch(setIsReportError(true))
      }
    } catch (err) {
      const error: AxiosError | any = err
      const errorStatus = error?.response?.status
      dispatch(setIsReportError(true, errorStatus))
    } finally {
      dispatch(setIsReportLoading(false))
    }
  }
