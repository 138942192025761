import React, { memo, useMemo } from 'react'
import { Box, Flex } from '@chakra-ui/core'

import { PinType, getPostsIdsFromLocalStorage } from '../utils'
import { CustomBadge } from 'components/helperStyles'
import { Post } from 'redux/reducers/feed'
import { CreatorReceiverTimeDisplay } from './CreatorReceiverTimeDisplay'
import { PostPin } from './PostPin'
import { PostMenu } from './PostMenu'
import keys from 'constants/keys'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface IProps {
  created_by: string
  created_at_ts: number
  post_id: string
  postType: number
  onEditPost?: () => void
  isEdit?: boolean
  postGroupName: string[]
  myUID: string
  groups: string[]
  users: string[]
  tags: string[]
  postOriginUsers: string[]
  handlePinPost: (postId: string, pinType: PinType) => void
  is_manager_pin?: boolean
  is_self_pin?: boolean
  isNewPost?: boolean
  isAllPostCollapsed: boolean
  isViewed: boolean
  commentsCounter: number
  posts: Post[]
  isDoublePost?: boolean
  title: string
}
const PostHeader = memo(
  ({
    created_by,
    created_at_ts,
    post_id,
    onEditPost,
    isEdit = true,
    postGroupName,
    myUID,
    groups,
    users,
    tags,
    postOriginUsers,
    handlePinPost,
    is_manager_pin,
    is_self_pin,
    isNewPost,
    isAllPostCollapsed,
    isViewed,
    commentsCounter,
    posts,
    isDoublePost,
    postType,
    title,
  }: IProps) => {
    const pinedPostsObject = getPostsIdsFromLocalStorage()
    const { theme } = useSelector((state: RootState) => state.general)
    const { permission } = useSelector((state: RootState) => state.config.config)

    const editRole = isEdit && created_by === myUID
    const isAdminPostPermission = permission?.posts === keys.USER_ACTIONS_MAP.APP_ADMIN

    const isShowPinMenuItem = useMemo(() => {
      return (
        isNewPost &&
        !is_manager_pin &&
        (pinedPostsObject[myUID!]?.includes(post_id) ||
          pinedPostsObject?.[myUID!]?.length < 2 ||
          !pinedPostsObject?.[myUID!])
      )
    }, [isNewPost, is_manager_pin, myUID, pinedPostsObject, post_id])

    const isShowPinManagerMenuItem = useMemo(() => {
      return (
        (editRole &&
          isNewPost &&
          !is_self_pin &&
          !posts.some((post) => post.created_by === myUID && post.is_manager_pin === true)) ||
        is_manager_pin
      )
    }, [editRole, isNewPost, is_manager_pin, is_self_pin, myUID, posts])

    const isShowBadge = !isViewed && commentsCounter !== undefined && !isDoublePost && isAllPostCollapsed

    return (
      <Box cursor="pointer">
        <Flex justifyContent="space-between">
          <CreatorReceiverTimeDisplay
            {...{
              created_by,
              postOriginUsers,
              users,
              postType,
              title,
              created_at_ts,
              postId: post_id,
              postGroupName,
              tags,
            }}
          />

          <PostPin {...{ post_id, is_manager_pin, isNewPost, pinedPostsObject }} />

          {isShowBadge ? (
            <CustomBadge
              padding="0"
              height="23px"
              minW="23px"
              mx="5px"
              mt="8px"
              fontSize="12px"
              bg={theme?.elementsColor}
            >
              {commentsCounter + 1}
            </CustomBadge>
          ) : is_manager_pin && !editRole ? (
            <></>
          ) : (
            <>
              {(editRole || isShowPinMenuItem || isShowPinManagerMenuItem || isAdminPostPermission) && (
                <PostMenu
                  isGroup={keys.POST_TYPE.GROUP_POST === postType}
                  {...{
                    groups,
                    users,
                    tags,
                    is_manager_pin,
                    pinedPostsObject,
                    post_id,
                    handlePinPost,
                    onEditPost,
                    editRole,
                    isAdminPostPermission,
                    isShowPinMenuItem,
                    isShowPinManagerMenuItem,
                  }}
                />
              )}
            </>
          )}
        </Flex>
      </Box>
    )
  }
)

export default PostHeader
