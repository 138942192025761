import React, { memo, useCallback, useMemo } from 'react'
import { Box, Divider, useDisclosure } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import LikesModal from './LikesModal'
import { RootState } from 'constants/interfaces'
import { useSearchPage } from 'hooks/useSearchPage'
import { Post } from 'redux/reducers/feed'
import 'video-react/dist/video-react.css'
import { ViewsModal } from './ViewsModal'
import { useViewedPosts } from './postHooks/useViewedPosts'
import { useLikedPosts } from './postHooks/useLikedPosts'
import { usePinnedPosts } from './postHooks/usePinedPosts'
import { ExpandedPost } from './ExpandedPost'
import { CollapsedPost } from './CollapsedPost'
import { CardWrap } from 'components/helperStyles'

import './style.css'
import useComments from 'hooks/useComments'
import PostHeader from './PostHeader'
import keys from 'constants/keys'
import { PostModal } from 'components/NewsFeed/PostModal'

export interface EnhancedPost extends Post {
  isDoublePost?: boolean
  handleOpenSelectedPost?: (postID: string) => void
  isAllPostCollapsed: boolean
  posts: Post[]
  selectedPostID?: string
  index?: number
}

export const PostWrapper = memo(
  ({
    handleOpenSelectedPost,
    isDoublePost,
    isAllPostCollapsed,
    posts,
    selectedPostID,
    index,
    ...post
  }: EnhancedPost) => {
    const { isSearchPage } = useSearchPage()
    const { isOpen: isViewModalOpen, onOpen: onViewModalOpen, onClose: onCloseViewModal } = useDisclosure()
    const { isOpen: isEditPotModalOpen, onOpen: onEditPostModalOpen, onClose: onCloseEditPostModal } = useDisclosure()
    const { isOpen: isLikesModalOpen, onOpen: onLikesModalOpen, onClose: onCloseLikesModal } = useDisclosure()
    const myUID = useSelector((state: RootState) => state.auth.uid!)
    const { theme } = useSelector((state: RootState) => state.general)

    const { commentsCounterLoading } = useComments(post.post_id)
    const commentsCounter = useSelector((state: RootState) => state.comments.commentsCounter[post.post_id])

    const { inView, handleUpdatedViewedPost, requireToUpdateViews, isViewedPost, ref } = useViewedPosts({
      post_id: post.post_id,
      created_by: post.created_by,
      groups: post.groups,
      users: post.users,
      tags: post.tags ?? [],
      viewed: post.viewed,
      myUID,
      isSearchPage,
      liked: post.liked,
      isDoublePost,
    })
    const { isLikedPost, likeHandler, isShowLikeButton } = useLikedPosts({
      post_id: post.post_id,
      created_by: post.created_by,
      groups: post.groups,
      tags: post.tags ?? [],
      users: post.users,
      liked: post.liked,
      myUID,
      isSearchPage,
      handleUpdatedViewedPost,
    })

    const { handlePinPost } = usePinnedPosts({
      myUID,
      pin_created_at_ts: post.pin_created_at_ts,
      created_at_ts: post.created_at_ts,
      post_id: post.post_id,
      groups: post.groups,
      users: post.users,
      tags: post.tags ?? [],
      is_manager_pin: post.is_manager_pin,
      posts,
      isSearchPage,
    })

    const handleLikesModal = useCallback(() => {
      Object.keys(post.liked).filter((key) => key !== myUID).length && onLikesModalOpen()
    }, [myUID, onLikesModalOpen, post.liked])

    const handleViewsModal = useCallback(() => {
      Object.keys(post.viewed).filter((key) => key !== myUID).length && onViewModalOpen()
    }, [myUID, onViewModalOpen, post.viewed])

    const isWhiteBackGround = useMemo(() => {
      return isDoublePost || isViewedPost || isLikedPost || !isAllPostCollapsed
    }, [isAllPostCollapsed, isDoublePost, isLikedPost, isViewedPost])

    const isShowProgressAnimation = useMemo(
      () => inView && !isViewedPost && !isLikedPost && !isAllPostCollapsed && post.type !== keys.POST_TYPE.GROUP_POST,
      [inView, isAllPostCollapsed, isLikedPost, isViewedPost, post.type]
    )
    const getBackGround = () => {
      if (selectedPostID === post.post_id && !isDoublePost && isAllPostCollapsed) {
        return theme?.backgroundLight2
      }

      if (!isWhiteBackGround) {
        return theme?.backgroundLight
      }
      return '#fff'
    }

    return (
      <>
        <CardWrap
          borderTopLeftRadius={
            isAllPostCollapsed && index === 0
              ? '12px !important'
              : !isAllPostCollapsed
              ? '12px !important'
              : '0 !important'
          }
          borderTopRightRadius={
            isAllPostCollapsed && index === 0
              ? '12px !important'
              : !isAllPostCollapsed
              ? '12px !important'
              : '0 !important'
          }
          onClick={() =>
            isAllPostCollapsed || (!isAllPostCollapsed && post.type === keys.POST_TYPE.GROUP_POST)
              ? handleOpenSelectedPost?.(post.post_id)
              : undefined
          }
          backGround={getBackGround()}
          isAllpostCollapsed={isAllPostCollapsed}
          doublePost={isDoublePost}
          boxShadowColor={theme?.elementsColor}
        >
          <Box>
            <Box
              className={`progressBase ${isShowProgressAnimation ? 'progressAnimating' : ''}`}
              style={{ '--progress-base-color': theme?.elementsColor } as React.CSSProperties}
            />
            <PostHeader
              created_by={post.created_by}
              created_at_ts={post.created_at_ts}
              post_id={post.post_id}
              groups={post.groups}
              tags={post.tags}
              users={post.users}
              postGroupName={post.post_origin_group_names}
              onEditPost={onEditPostModalOpen}
              myUID={myUID}
              handlePinPost={handlePinPost}
              isNewPost={post.is_new || post.is_new_version}
              is_self_pin={post.is_self_pin}
              is_manager_pin={post.is_manager_pin}
              postOriginUsers={post.post_origin_users}
              isAllPostCollapsed={isAllPostCollapsed && !isDoublePost}
              isDoublePost={isDoublePost}
              commentsCounter={commentsCounter}
              postType={post.type}
              title={post.title}
              posts={posts}
              isViewed={
                post.viewed[myUID] ||
                (requireToUpdateViews.includes(myUID) && post.created_by === myUID) ||
                !requireToUpdateViews.includes(myUID)
              }
            />
          </Box>

          <Box display={isAllPostCollapsed && !isDoublePost && !isSearchPage ? 'block' : 'none'}>
            <CollapsedPost
              commentsCounter={commentsCounter}
              commentsCounterLoading={commentsCounterLoading}
              isShowLikeButton={isShowLikeButton}
              isLikedPost={isLikedPost}
              onViewModalOpen={handleViewsModal}
              requireToUpdateViews={requireToUpdateViews}
              handleLikesModal={handleLikesModal}
              isDoublePost={isDoublePost}
              isAllPostCollapsed={isAllPostCollapsed}
              {...post}
            />
          </Box>

          <Box ref={ref} display={isAllPostCollapsed && !isDoublePost && !isSearchPage ? 'none' : 'block'}>
            <ExpandedPost
              commentsCounter={commentsCounter}
              commentsCounterLoading={commentsCounterLoading}
              isShowLikeButton={isShowLikeButton}
              isLikedPost={isLikedPost}
              onViewModalOpen={handleViewsModal}
              requireToUpdateViews={requireToUpdateViews}
              likeHandler={likeHandler}
              handleLikesModal={handleLikesModal}
              isDoublePost={isDoublePost}
              isAllPostCollapsed={isAllPostCollapsed}
              selectedPostID={selectedPostID}
              {...post}
            />
          </Box>

          {isEditPotModalOpen && (
            <PostModal
              isEditing={true}
              isOpen={isEditPotModalOpen}
              onClose={onCloseEditPostModal}
              postType={post.type}
              postTitle={post.title}
              {...post}
            />
          )}
          {isLikesModalOpen && (
            <LikesModal isOpen={isLikesModalOpen} onClose={onCloseLikesModal} post={post} {...post} />
          )}
          {isViewModalOpen && <ViewsModal isOpen={isViewModalOpen} onClose={onCloseViewModal} {...post} />}
        </CardWrap>
        {isAllPostCollapsed && !isDoublePost && <Divider my="0" />}
      </>
    )
  }
)
