import { Avatar, Box, Flex, Text } from '@chakra-ui/core'
import { SecretID } from 'components/CommonComponents/SecretID'
import { TooltipList } from 'components/CommonComponents/TooltipList'
import { DateText } from 'components/Elements'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import useAvatar from 'hooks/useAvatar'
import React, { useMemo } from 'react'
import { HiChevronDoubleRight } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { convertEpocTimeStampToDate } from 'utils'

interface IProps {
  created_by: string
  postOriginUsers: string[]
  users: string[]
  tags: string[]
  created_at_ts: number
  postGroupName: string[]
}
export const CreatorReceiverTimeDisplay = ({
  created_by,
  postOriginUsers,
  users,
  tags,
  created_at_ts,
  postGroupName,
}: IProps) => {
  const avatar = useAvatar(created_by)
  const retailUsersObject = useSelector((state: RootState) => state.config.config.retailUsersObject)
  const { retail_users, tags: retailTags } = useSelector((state: RootState) => state.config.config)
  const isRtl = useSelector(isRtlSelector)
  const { encodedUID } = useSelector((state: RootState) => state.config)

  const usersNames = useMemo(() => {
    if (postOriginUsers.length > 0 && retailUsersObject) {
      return postOriginUsers
        .filter((user) => Object.keys(retailUsersObject).includes(user))
        .map(
          (user) =>
            `${retailUsersObject?.[user].first_name} ${
              retailUsersObject?.[user].last_name ? retailUsersObject?.[user].last_name : ''
            }`
        )
    } else {
      return retail_users
        ?.filter((user) => users?.includes(user.uid))
        .map((user) => `${user.first_name} ${user.last_name ? user.last_name : ''}`)
    }
  }, [postOriginUsers, retailUsersObject, retail_users, users])

  const tagsNames = useMemo(() => {
    if (!tags || !tags.length || !retailTags) {
      return []
    }
    return retailTags.reduce((acc, tag) => {
      if (tags.includes(tag.sk)) {
        acc.push(tag.name)
      }
      return acc
    }, [] as string[])
  }, [retailTags, tags])

  const mergedGroupsUsersTagsNames = [...postGroupName, ...usersNames, ...tagsNames]

  const groupNames = useMemo(() => {
    return mergedGroupsUsersTagsNames.length < 2
      ? mergedGroupsUsersTagsNames
      : `${mergedGroupsUsersTagsNames[0]} + ${mergedGroupsUsersTagsNames.length - 1}`
  }, [mergedGroupsUsersTagsNames])

  const postCreator = useMemo(() => {
    if (retailUsersObject && retailUsersObject?.[created_by]) {
      const firstName = retailUsersObject[created_by].first_name
        ? retailUsersObject[created_by].first_name
        : keys.DEFAULT_ADMIN_USER_NAME
      const lastName = retailUsersObject[created_by].last_name ? retailUsersObject[created_by].last_name : ''
      return firstName + ' ' + lastName
    }
    return keys.COMPANY_NAME
  }, [created_by, retailUsersObject])

  return (
    <Flex>
      <Avatar style={{ width: '40px', height: '40px' }} src={avatar} />
      <Box mx="10px">
        <Flex alignItems="center" flexWrap={'wrap'} lineHeight="18px">
          <TooltipList items={mergedGroupsUsersTagsNames}>
            <Text fontSize="14px" fontWeight="600">
              {postCreator}
            </Text>
          </TooltipList>
          &nbsp;
          <HiChevronDoubleRight style={{ transform: `rotate(${180 * +isRtl}deg)` }} />
          &nbsp;
          <TooltipList items={mergedGroupsUsersTagsNames}>
            <Text fontSize="14px" fontWeight="600" cursor="pointer">
              {groupNames}
            </Text>
          </TooltipList>
        </Flex>
        <Flex alignItems="center" lineHeight="normal" style={{ columnGap: '3px' }}>
          <DateText>{convertEpocTimeStampToDate(created_at_ts)}</DateText>
          <SecretID value={encodedUID} />
        </Flex>
      </Box>
    </Flex>
  )
}
